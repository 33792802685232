$(function(){
    $.fn.myScroll = function(options) {
//        return this;
         var methods = {
             setBorders: function(width,height) {
                 var set=$(this).data("myScroll");
                 set.width=width;
                 set.height=height;
                 $(this).data("myScroll", set);
                 setBorders($(this));
             },
             destroy: function() {
                 var obj=$(this),set=obj.data("myScroll");
                 if(set){
                     obj.data("myScroll",false);
                 }
                 var move=obj.find(">.MyScInside>.MyScMove");
                 if(move.length>0){
                      obj.html(move.html());
                 }
             },
             setTop:function(top){
                  moveGoToPosition($(this),top);
             },
             setBottom:function(){
                 moveGoToPosition($(this),99999999);
             },
             setTopEl:function(el_index){
                 var bum,
el=$(this).find(">.MyScInside>.MyScMove").children(),top=0;
                 bum=el.eq(el_index);
                 if(bum.length){
                     bum=bum.position();
                     top=bum.top;
                 }

                 moveGoToPosition($(this),top);
             },
             update: function(){
                 setBorders($(this));
             },
             deleteAllElements:function(){
                 var th=$(this),move=th.find(">.MyScInside>.MyScMove");
                 th.find(">.MyScLeft").hide();
                move.html("").css("top","0px");
             },
             addAllHtml:function(html){
                var th=$(this),move=th.find(">.MyScInside>.MyScMove");
                move.html(html);
                setBorders(th);
            },
             addElement: function(element,security){
                 var
th=$(this),move=th.find(">.MyScInside>.MyScMove"),to_move=0;
if(move.height()-20<=(-intval(move.css("top"))+th.find(">.MyScInside").height()) 
|| move.height()<=th.find(">.MyScInside").height()){
                     to_move=1;
                 }
                 if(!security){
                     move.append(element);
                 }
                 else{
                     move.append("<div></div>");
                     var i,child=move.children(),bum;
                     for(i=child.length-1;i>-1;i--){
                         bum=child.eq(i);
                         if(bum.html()==""){
                             bum.html(element);
                             break;
                         }
                     }
                 }

//                if(to_move==1){
//                    $.qLog("down");
//                    moveGoToPosition(th,99999999);
//                }
                 setBorders(th);
                 if(to_move==1){
                     moveGoToPosition(th,99999999);
                 }
             }

         };
         function goToPosition(tobj,top){
             var
obj=tobj.closest(".myScroll"),thumb=obj.find(">.MyScLeft .MyScThumb"),move=obj.find(">.MyScInside>.MyScMove"),
line=obj.find(">.MyScLeft>.MyScTrack").height()-thumb.height(),
to_move=move.outerHeight(true)-obj.find(">.MyScInside").height();
             if(top>line){
                 top=line;
             }
             if(top<-2){
                 top=-2;
             }
             thumb.css("top",top+"px");
             if(top<0){
                 top=0;
             }
             var percent=top/line;
             to_move=to_move*percent*(-1);
             move.css("top",to_move+"px");
             obj.trigger("myscroll");
         }
         function onDown(event){
             var tobj=$(this),sc=$("body");
             goToPosition(tobj, layerY(tobj,$.getYevent(event)));
             sc.on("mouseup touchend",topos2);
             sc.on("mousemove touchmove",topos);
             function topos(e){
                 goToPosition(tobj, layerY(tobj,$.getYevent(e)));
             }
             function topos2(){
                 sc.off("mousemove touchmove",topos).off("mouseup touchend",topos2);
             }

             return false;
         }
         function layerY(obj,pageY){
             var page=obj.closest(".myScroll").find(">.MyScInside").offset();
             page=page.top;
             return (pageY-page-obj.closest(".myScroll").find(">.MyScLeft .MyScThumb").height()/2);
         }
         function onClick(event) {
             var tobj=$(this);
             goToPosition(tobj,layerY(tobj,$.getYevent(event)));
         }
         function onScroll(event, delta){
             var obj=$(this),top=intval(obj.find(">.MyScInside>.MyScMove").css("top"))*(-1)
,
             set=obj.data("myScroll");

             if(delta>0){
                 top-=set.scrollStep;
             }
             if(delta<0){
                 top+=set.scrollStep;
             }
             moveGoToPosition(obj, top);
             $("#smoke").hide();
if(obj.closest(".myScroll").find(">.MyScLeft:visible").length){
                  return false;
             }

         }
         function moveGoToPosition(obj,top){
             var thumb=obj.find(">.MyScLeft .MyScThumb"),move=obj.find(">.MyScInside>.MyScMove"),
             inside=obj.find(">.MyScInside");

             var  to_move=move.outerHeight(true)-inside.height(),
             percent=(top)/to_move,
             line=obj.find(">.MyScLeft .MyScTrack").height()-thumb.height();


             to_move= line*percent;
             if(to_move<0){
                 to_move=-2;
             }
             if(!obj.find(">.MyScLeft:visible").length){
                 return true;
             }
             goToPosition(move,to_move);
         }
         function setBorders(obj){
             var move=obj.find(">.MyScInside>.MyScMove"),inside=obj.find(">.MyScInside");
             var type=false,set=obj.data("myScroll"),move_height=move.outerHeight(true);
             if(typeof set!="undefined"){
                 if(set.height==-1){
                     type=set.type;
                     var maxHeight=$.scrollMaxHeight(type),all=obj.closest(".win_all"),bum;
                     obj.hide();
                     bum=all.outerHeight( true)+obj.outerHeight( true)-obj.height();
                     obj.show()
                     set.height=maxHeight-bum;
                 }
obj.find(">.MyScInside,>.MyScLeft").height(set.height);
                 if(set.inBorder){
//obj.find(".MyScMove").width(set.width-obj.find(".MyScLeft").width());
                     inside.width(set.width);
                 }
                 else{
                     //obj.find(".MyScMove").width(set.width);
inside.width(set.width+obj.find(">.MyScLeft").width());
                 }
if(move_height-intval(set.height)<-intval(move.css("top"))){
move.css("top",(-(move_height-intval(set.height)))+"px");
                 }
                 if(move.outerHeight(true)>set.height){
                     obj.find(">.MyScLeft").show();
                 }
                 else{
                     obj.find(">.MyScLeft").hide();
                     move_height=move.css({"top":"0px"}).outerHeight( true); obj.find(">.MyScInside,>.MyScLeft").height(move_height);
//obj.find(".MyScInside,.MyScMove").width(set.width);
                 }
                 moveGoToPosition(obj,  -intval(move.css("top")));
                 if(type){
                     $.makeModule(type);
                 }
             }
         }
         function prepareSlider(obj){
             var tobj=obj.children().clone(true);
             var html="";//=obj.html();
            // obj.find("script").remove();
             html=
             "<div class='MyScInside'>"+
                 "<div class='MyScMove'>"+html+"</div>"+
             "</div>"+
             "<div class='MyScLeft'>"+
                 "<div class='MyScTrack'>"+
                     "<div class='MyScThumb'></div>"+
                 "</div>"+
             "</div>";
             obj.html(html);
             obj.find(">.MyScInside>.MyScMove").append(tobj);
             var set=obj.data("myScroll");
             if(set.margin>0){
obj.find(">.MyScLeft>.MyScTrack").css({"height":(set.height-set.margin)+"px","margin-top":set.margin+"px"})
             }
             setBorders(obj);
         }

         var last_this=this;
         var arg =Array.prototype.slice.call(arguments,1);
         return this.each(function() {
             if(methods[options]){
                 return methods[options].apply(this,arg);
             }
             else if (typeof options === 'object' || !options){
                 var $this = $(this),
                 data = $this.data("myScroll");

                 if(!data){
                     $this.addClass("myScroll");
                     var settings = $.extend( {
                         height : '150',
                         width: '300',
                         scrollStep: 30,
                         inBorder:1,
                         margin:0,
                         type:"module"

                     }, options);

                     data = $this.data("myScroll", settings);
                     prepareSlider($this);
                     $this.find(">.MyScLeft").bind("click", onClick); $this.find(">.MyScLeft,>.MyScLeft>.MyScThumb").bind("mousedown touchstart", onDown);
                     $this.bind("mousewheel", onScroll);
                     if(typeof options.right!="undefined"){ $this.find(".MyScLeft").css("right",intval(options.right));
                     }
                     if(typeof options.cur!="undefined"){ $this.myScroll("setTop",-intval(options.cur));
                     }
                     if($this.is("[scroll_top]")){ $this.myScroll("setTop",-intval($this.attr("scroll_top")));
                     }
                 }
                 data.first = true;
                 return;
             }
             else{
                 $.error('myScroll: no method: '+ options);
             }
         });
     };
    $.fn.mySelect = function(options) {
        var methods = {
            setBorders: function(width,max_scroll_height) {
                var set=$(this).data("mySelect");
                if(!set){
                    return false;
                }
                set.width=width;
                set.max_scroll_height=max_scroll_height;
                $(this).data("mySelect", set);
                setBorders($(this));
            },
            destroy: function() {                
            },
            triggerCurrent: function(){
                 $(this).find(".myEl[val='"+$(this).attr("val")+"']").trigger("click");
            },
            setVal: function(val){
                $(this).find(".myEl[val='"+val+"']").trigger("click");
            }
            
        };
        
        function setBorders(obj){
            var set=obj.data("mySelect"),
            whole=obj.find(".MySeWhole"),            
            top=whole.outerHeight(true)-(intval(whole.css("border-bottom-width"))),
            width;
            obj.find(".MySeInside,.MySeWhole").width(set.width);
            width=2*set.width-obj.find(".MySeInside").outerWidth(true);
            if(width>0){
                obj.find(".MySeInside").width(width);
            }
            obj.find(".MySeBox").width(set.width);//.height(height);
            var border=intval(obj.find(".MySeBox").css("borderLeftWidth"));
            width=2*set.width-obj.find(".MySeBox").outerWidth(true)+border*2;
            obj.find(".MySeBox").width(width).css({"top":top,"left":-border}).
            myScroll({height:set.max_scroll_height,width:width});
            obj.find(".MySeBox").show().myScroll("setBorders",width,set.max_scroll_height).hide();
        }
        function onClickElement(obj){
            if(obj.is(".na")){
                //return false;
            }
            
            var whole=obj.closest(".mySelect"),val,name,tmp;
            obj.closest(".MySeBox").hide();
            
            if(obj.children().length){
                whole.find(".MySeInside").html("").append(obj.children().clone(true,true));
            }
            else{
                whole.find(".MySeInside").html(obj.html());
            }
            
            
            //whole.find(".MySeInside .myForm").myForm();
            if(obj.attr('val')){
                val=obj.attr('val');
            }
            else{
                val=obj.index()+1;
            }
            whole.attr("val",val);
            whole.find(".MySeBox").css("top",(whole.outerHeight(true)-5)+"px");            
            
            name=whole.attr("name");
            if(name){
               tmp=whole.find("input[type='hidden'][name='"+name+"']");
               if(tmp.length){
                   tmp.attr("value",val);
               }
               else{
                   whole.append("<input type='hidden' name='"+name+"' value='"+val+"'>");
               }
            }
            whole.trigger("change",val);
            rounded(whole.find(".MySeWhole"), 14);
            return false;
        }
        function rounded(whole,radius_in){
            var data=whole.closest(".mySelect").data("mySelect");
            var radius=0,border_width=intval(data.border_width);
            if(radius_in){
                radius=intval(data.radius);
            }
            
            whole.css({"border-bottom-right-radius":radius+"px",
            "-moz-border-radius-bottomright":radius+"px",
            "-webkit-border-bottom-right-radius":radius+"px",
            "border-bottom-left-radius":radius+"px",
            "-moz-border-radius-bottomleft":radius+"px",
            "-webkit-border-bottom-left-radius":radius+"px"});
            whole.css("border-bottom-width",border_width+"px");
        }
        function onClickWhole(obj){
            
            obj=obj.closest(".MySeWhole");
            var box=obj.find(".MySeBox"),all=obj.closest(".mySelect"),val=all.attr("val"),el,top,
            whole=all.find(".MySeWhole");
            if(box.is(":visible")){
                box.hide();
                rounded(whole, 14);
                
            }
            else{
                setBorders(obj.closest(".mySelect"));
                box.show();
               
                el=obj.find(".myEl[val='"+val+"']");
                if(el.length<1){
                    el=obj.find(".myEl:eq("+val+")");
                }
                top=el.position();
                top=top.top;
                all.myScroll("setTop",top);
                rounded(whole, 0);
            }
            
        }
        function init(obj){  
            obj.children("div").addClass("myEl");
            var html=obj.html(),set=obj.data("mySelect");           
            html=
            "<div class='MySeWhole'>"+
                "<div class='MySeInside'></div>"+ 
                "<div class='MySeRight'></div>"+
                "<div class='MySeBox'>"+html+"</div>"+
            "</div>";
            
            obj.html(html);
            setBorders(obj);
            obj.find(".myEl").click(function(){return onClickElement($(this));});
            obj.find(".MySeInside,.MySeRight").bind("click",function(){onClickWhole($(this))});
            if(set.val &&  obj.find(".myEl[val='"+set.val+"']").length){
                obj.find(".myEl[val='"+set.val+"']").trigger("click");
            }
            else{
                if(obj.find(".myEl").is(":not(.na)")){
                    obj.find(".myEl:not(.na)").eq(0).trigger("click");
                }
                else{
                    obj.find(".myEl").eq(0).trigger("click");
                } 
            }
            
        }
        var last_this=this; 
        var arg =Array.prototype.slice.call(arguments,1);
        return $(this).each(function(key) {  
            
            if(methods[options]){  
                return methods[options].apply(last_this,arg);
            }
            else if (typeof options === 'object' || !options){ 
                var $this = $(this),
                data = $this.data("mySelect");  
                if(!data){    
                    $this.addClass("mySelect");
                    var settings = $.extend( {
                        max_scroll_height : 150,
                        width: 300,
                        radius: 14,
                        border_width:3
                    }, options);
                    if($this.children().length>0){
                        data = $this.data("mySelect", settings);
                        init($this); 
                        data.first = true;
                        var cur=false;
                        if(typeof settings.cur!=="undefined"){
                            cur=settings.cur;
                        }    
                        if(!cur){
                            if(typeof $this.attr("cur")!=="undefined"){
                                cur=$this.attr("cur");
                            }
                            
                        }
                        if(cur){
                            methods.setVal.call($this,cur);
                        }
                    }
                }                
                return;
            }
            else{
                $.error('mySelect: no method: '+ options);
            }
        });
    }
});