function mySemaphore(conf){
    this.cur=0;
    this.max=1;
    this.timeout=1500;
    this.timerArr=[];
    var i;
    if(typeof conf=="object"){
        for(i in conf){
            if(typeof this[i]!="undefined"){
                this[i]=conf[i];
            }
        }
    }
    this.funArr=[];
}
mySemaphore.prototype={
    use: function(force) {
        if(this.check() || force){
            this.cur++;
            var curThis=this;
            if(this.timeout>0){
                this.timerArr.push( setTimeout(function(){
//                    $.qLog(" clear:"+curThis.timeout+" ");
                    curThis.clear();
                },this.timeout));
            }
            return true;
        }
        return false;
    },
    check:function(){
        if(this.cur<this.max){
            return true;
        }
        return false;
    },
    setTimeout:function(timeout){
        this.timeout=timeout;
    },
    //use function if semaphore is clear
    //or function will be called during clearing semaphore
    useFun:function(fun){
        if(this.check()){
            fun();
        }
        else{
            this.funArr.push(fun);
        }
    },
    clear:function(){
        this.cur--;
        if(this.cur<0){
            this.cur=0;
        }
        if(this.timerArr.length){
            clearTimeout(this.timerArr.shift());
        }
        while(this.check() && this.funArr.length){
            (this.funArr.shift())();
        }
    }
};
var _semaphoreXHR=new mySemaphore();
_semaphoreXHR.sendSemaphore=new mySemaphore({timeout:3000});
_semaphoreXHR.funOnComplite=function(){};
_semaphoreXHR.funOnCompliteForce=false;
_semaphoreXHR.funSendingArr=[];
_semaphoreXHR.funAfterClearArr=[];
_semaphoreXHR.objForceLocationArr=[];
_semaphoreXHR.addForceLocation=function(url,post_obj){
    if(!post_obj){
        post_obj={};
    }
    post_obj.fromXHR=1;
    this.objForceLocationArr.push({url:url,post_obj:copyObj(post_obj)});
};
_semaphoreXHR.useForceLocation=function(){
    if(this.objForceLocationArr.length==0){
        return false;
    }
    return this.objForceLocationArr.shift();
};
_semaphoreXHR.wantSend=function(fun_send,url){
    if(typeof this.funOnCompliteForce=="function"){
        this.clear();
        this.funSendingArr.push(fun_send);
        this.funOnCompliteForce(url);
    }
    else if(this.sendSemaphore.check()){

        fun_send();

//        $.qLog("justSend");
    }
    else{
        this.funSendingArr.push(fun_send);

        this.funOnComplite(url);
        this.funOnComplite=function(){};
    }
};
_semaphoreXHR.makeAfterClear=function(fun_clear){
    if(this.check()){
        fun_clear();
    }
    else{
        this.funAfterClearArr.push(fun_clear);
    }
};
_semaphoreXHR.tryClear=function(){
    this.clear();
    while(this.check() && this.funAfterClearArr.length){
        (this.funAfterClearArr.shift())();
    }
};

_semaphoreXHR.sendXhr=function(){
    this.funOnCompliteForce=false;
    while(this.funSendingArr.length){
        (this.funSendingArr.shift())();
    }
    this.sendSemaphore.clear();
};
/*
 * this function must trigger _semaphoreXHR.sendXhr();
 */
_semaphoreXHR.setBeforeSendXhr=function(fun_complite){
    if(this.sendSemaphore.use()){
        //set new
//        $.qLog(" setNew ");
        this.funOnComplite=fun_complite;
        return true;
    }
    else{
        //prolong blocking

        if(this.sendSemaphore.timeout>0){
            clearTimeout(this.sendSemaphore.timerArr.shift());
            var curThis=this.sendSemaphore;
            this.sendSemaphore.timerArr.push( setTimeout(function(){curThis.clear();},this.sendSemaphore.timeout));
        }

    }
    return false;
};
_semaphoreXHR.setBeforeSendXhrForce=function(fun_complite){
    this.funOnCompliteForce=fun_complite;
};

//$(function(){
//    _semaphoreXHR.setBeforeSendXhr(function(){
//        var i;
//        for(i=0;i<10;i++){
//            $("#qLOG").show().find(".cont").html($("#qLOG .cont").html()+i+" ");
//        }
//        setTimeout(function(){
//            _semaphoreXHR.sendXhr();
//        },2000);
//    });
//    _semaphoreXHR.setBeforeSendXhr(function(){
//        $("#qLOG").show().find(".cont").html($("#qLOG .cont").html()+"zlo ");
//        setTimeout(function(){
//            _semaphoreXHR.sendXhr();
//        },2000);
//    });
//    _semaphoreXHR.wantSend(function(){
//       alert('send1');
//    });
//    _semaphoreXHR.wantSend(function(){
//       alert('send2');
//    });
//});
