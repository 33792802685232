var _currentPage={
    page:"",
    p:0,
    popup:"",
    p:0,
};
var startTimeServer,_isMobile=false;
$(function(){

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        _isMobile = true;
        $("body").addClass("isMobile");
    }
    else{
        _isMobile = 'ontouchstart' in document.documentElement;
    }

});

function setCurrentPage(page,p){
    if(!p){
        p=0;
    }
    _currentPage.page=page;
    _currentPage.p=p;
}
function getCurrentPage(with_p){
    if(with_p && _currentPage.p!=0){
        return _currentPage.page+"/"+_currentPage.p+"/";
    }
    return _currentPage.page;
}
function setCurrentPopup(page,p){
    if(!p){
        p=0;
    }
    _currentPage.popup=page;
    _currentPage.popup_p=p;
}
function getCurrentPopup(with_p){
    if(with_p && _currentPage.popup_p!=0){
        return _currentPage.popup+"/"+_currentPage.popup_p+"/";
    }
    return _currentPage.popup;
}
function extendSessionPost(data){
    var add2data=copyObj(_SESSION_COOKIE_VIEW);

    var valType=typeof data;
    if(valType=="string"){
        if(data.length>0){
            data=data+"&";
        }
        var str_arr=[],i;
        for(i in add2data){
            str_arr.push(i+"="+add2data[i]);
        }
        data=data+str_arr.join("&");
    }
    else{
        if(valType=="undefined"){
            data={};
        }
        data=$.extend(true,data,add2data);
    }
    return data;
}
var _GAMES_TRIGGERS={
    triggerClose:function(page){
        if(page==getCurrentPage()){

        }
        else{
            sendCasinoMess("triggerClose");
        }
    },
};
$(function(){
    window.addEventListener('message', function(e) {
        if(!_clientHost || e.origin==_clientHost || e.origin=="https://hotcandyland.com"){
            var data=e.data;
            try{
                 data=JSON.parse(data);
            }
            catch(e){}
            if(typeof data=="object" && typeof data[0]!="undefined" && data[0]){
                var arg =Array.prototype.slice.call(data,1),i;
                if(typeof _GAMES_TRIGGERS[data[0]]!="undefined"){
                    _GAMES_TRIGGERS[data[0]].apply(window,arg);
                }
            }
        }
    }, false);
    $.mypost=function(url,data,onSucc,dataType){
        data=extendSessionPost(data);
        $.post(url,data,onSucc,dataType);

    };
    $.captchaPutCode=function(token){
        $("input[name='captcha_code']").attr("value",token);
        $("#captchaCode").trigger("myChange");
    };
    $.getVideoPath=function(name){
        var lanAll={
            intro:["PL","FR","EN","JP"]
        };
        var lan=_LAN;
        if(!in_array(lan,lanAll[name])){
            lan="EN";
        }
        var path="/video/"+lan+"/"+name+"/index.m3u8";
        return path;
    };
    $.fn.blankCell=function(set_in_row,cell_classes){
        return this.each(function(){
           var th=$(this);
           var children=th.children();
           var el_count=children.length;
           var in_row,
           i,html="";
           if(!set_in_row){
               in_row=Math.floor(th.width()/children.eq(0).outerWidth(true))
           }
           else{
               in_row=set_in_row;
           }
           if(!cell_classes){
               cell_classes="cell blank";
           }
           if(el_count%in_row!=0){
            for(i=0;i<in_row-el_count%in_row;i++){
                html+="<div class='"+cell_classes+"'></div>";
            }
           }
           th.append(html);
        });
    };
    $.fn.cssrotate = function(d) {
        return this.css({
            '-moz-transform': 'rotate(' + d + 'deg)',
            '-webkit-transform': 'rotate(' + d + 'deg)',
            '-o-transform': 'rotate(' + d + 'deg)',
            '-ms-transform': 'rotate(' + d + 'deg)'
        });
    };
    $.fn.myLoad = function(arr) {
        var th = $(this), i, length = 0, loaded = 0;
        for(i in arr){
            length++;
        }
        for (i in arr) {
            $('<img/>').one("one load",function() {
                loaded++;
                if (loaded == length) {
                    th.trigger("myload");
                }
            }).attr('src', arr[i]);
        }
        return th;
    };
    $.fn.textInputMax=function(){
        return this.each(function(){
            var th=$(this);
            var maxInput=intval(th.attr('data-max'));
            th.off("keydown.inpMax").on("keydown.inpMax", function(e) {
                var th=$(this),cur_val=th.val();
                if (cur_val.length >= maxInput && !in_array(e.keyCode,[37,39,8,46])) {
                    $(this).val(cur_val.substr(0, maxInput));
                    return false;
                }
            });
        });
    };
    $.fn.makeReflex=function(conf,timeHandler){
    /*conf=[{type:2,left:8,top:47},{type:3,left:115,top:47}];
     *
     */
        return this.each(function(){
            var th=$(this),i,bum,html="";
            for(i in conf){
                bum=conf[i];
                html+="<div class='reflexGeneral' data-type='"+bum.type+"' style='left:"+bum.left+"px;top:"+bum.top+"px'></div>";
            }
            th.append(html);
            th.find(".reflexGeneral").each(function(){
                var th=$(this),indexRepeat=timeHandler.getNewIndex(),indexRepeatWait=timeHandler.getNewIndex(),
                repeat_arr=[500,2500];
                function makeAnim(){
                    var timeArr=[mt_rand(repeat_arr[0],repeat_arr[1]),mt_rand(100,200),mt_rand(100,200),mt_rand(700,1000)];
                    th.cssrotate(0);
                    timeHandler.setOne(indexRepeat,function(){
                        th.fadeIn(timeArr[2],function(){
                            timeHandler.setOne(indexRepeatWait,function(){
                                th.fadeOut(timeArr[3],function(){
                                    makeAnim();
                                });
                            },timeArr[1]);

                        });
                    },timeArr[0]);
                    repeat_arr=[2500,5500];
                }
                makeAnim();
                var rotation=0;
                timeHandler.add(function(){
                    rotation+=0.5;
                    rotation=rotation%360;
                    th.cssrotate(rotation);
                },40);
            });
        });
    };
    $.fn.takeObjTimeHanderIndex=function(timeHandler){
        var th=$(this);
        var data = th.data("thIndexDD");
        var timeIndex;
        if(!data){
            timeIndex=timeHandler.getNewIndex();
            th.data("thIndexDD",{handler:timeIndex});
        }
        else{
            timeIndex=data.handler;
        }
        return timeIndex;
    };
    $.fn.throwDown=function(timeHandler,timeHandlerIndex,conf){
        if(timeHandler.isIndexSet(timeHandlerIndex)){
            return false;
        }
        var obj=$(this);
        var t=0,t_max=100,v_arr=[7,5,2],g=0.4,v_index=-1;
        if(typeof conf!="undefined"){
            if(typeof conf.v_arr!="undefined"){
                v_arr=copyArray(conf.v_arr);
            }
        }
        timeHandler.set(timeHandlerIndex,function(){

            if(t==0){
                v_index++;
                if(typeof v_arr[v_index]!="undefined"){
                    t_max=2*v_arr[v_index]/g;
                }
                else{
                    timeHandler.clear(timeHandlerIndex);
                    return false;
                }
            }
            var top=v_arr[v_index]*t-g*t*t/2;
            t+=2;
            if(t>=t_max){
                t=0;
            }
            obj.css("top",-top+"px");
            return true;

        },50,function(){
            obj.css("top","0px");
        });
        return true;
    };

    $.fn.letterByletter=function(options,timeHandler){
        if(typeof timeHandler=="undefined" || !timeHandler){
            timeHandler=_moduleTimeHandler;
        }
        var methods = {
            clear:function(){
                var obj=$(this),data=obj.data("lBl");
                if(typeof data=="undefined" || typeof data.timeIndex=="undefined"){
                    return false;
                }
                timeHandler.clear(intval(data.timeIndex));
                obj.html(data.text);
                obj.trigger("lBl_end");
            },
            isEnd:function(){
                var obj=$(this),data=obj.data("lBl");
                if(typeof data=="undefined" || typeof data.timeIndex=="undefined"){
                    return false;
                }
                if(timeHandler.isIndexSet(data.timeIndex)){
                    return 0;
                }
                return 1;
            }

        };
        function init(obj){
            var data=obj.data("lBl");
            obj.css({"width":obj.width(),"height":obj.height()});
            var text,timeIndex=data.timeIndex;;
            if(data.text==""){
                data.text=obj.html()
            }
            else{
                text=data.text;
            }
            obj.data("lBl",data);
            var INTERVAL=intval(data.letterTime);
            if(intval(data.wholeTime)>0){
                INTERVAL=Math.round(intval(data.wholeTime)/text.length);
                INTERVAL=Math.max(1,INTERVAL);
            }
            obj.html("");
            var i=0;
            obj.trigger("lBl_start");
            timeHandler.set(timeIndex,function(){
                if(i<text.length){
                    obj.text(text.substr(0, i+1));
                    i++;
                }
                else{
                    obj.trigger("lBl_end");
                    timeHandler.clear(timeIndex);
                }
            },INTERVAL);
        }
        var last_this=this;
        var arg =Array.prototype.slice.call(arguments,1),ret;
        $(this).each(function(key) {

            if(methods[options]){
                ret= methods[options].apply(last_this,arg);
            }
            else if (typeof options === 'object' || !options){
                var $this = $(this),
                data = $this.data("lBl"),settings;
                if(!data){
                    $this.addClass("lBl");
                    settings = $.extend( {
                        letterTime : '100',
                        wholeTime: '-1',
                        text:"",
                    }, options);
                    settings.timeIndex=timeHandler.getNewIndex();
                }
                else{
                    methods["clear"].apply(last_this,arg);
                    settings = $.extend(data, options);
                }
                $this.data("lBl",settings);
                init($this);
                return;
            }
            else{
                $.error('letterByLetter: no method: '+ options);
            }
        });
        if(typeof ret=="undefined"){
            return $(this);
        }
        else{
            return ret;
        }


    };
    $.testTime=function(){
        $.mypost('/ajax',{page:"timetest"}, function(res){
            var obj=handleAjaxRes(res);
            alert(getCurrentTime()-obj.time);
        },"json");
    };
    $.fn.disappear=function(text){
        function begin(obj){

            var data=obj.data("myDis");
            if(data && data.queue[0]){
                data.wait=1;
                obj.html(data.queue[0]);
                data.queue.shift();
                obj.data("myDis",data);
                data=obj.data("myDis");
                changeOpacity(obj, 1.3);
            }
            else{
                obj.data("myDis",{"wait":0,"queue":[]});
            }
        }
        function changeOpacity(obj,opacity){
            obj.show();
            opacity-=step;
            opacity=Math.max(0,Math.min(100,opacity));
            obj.css("opacity",opacity);
            if(opacity>0){
                setTimeout(function(){
                    changeOpacity(obj, opacity);
                }, step_time);
            }
            else{
                obj.hide();
                begin(obj);
            }
        }
        var step=0.02,step_time=50;
        return this.each(function(){
            var obj=$(this),data=obj.data("myDis"),wait;
            if(data){
                data.queue.push(text);
                if(data.queue.length>3){
                    data.queue.shift();

                }
                obj.data("myDis",data);
                wait=data.wait;
            }
            else{
                wait=0;
                data={"wait":0,"queue":[text]};
                obj.data("myDis",data);
            }
            if(!wait){
                begin(obj);
            }
        });
    };
    $.fn.myEye=function(options){
        var settings={
            step_max:2,
            interval:100,
            scene_range:[10,40],
            width:0
        };
        if(typeof options=="object"){
            settings=$.extend(settings,options);
        }
        var th=$(this),img_max=settings.step_max,step=mt_rand(-5,-3),step_max=mt_rand(settings.scene_range[0],settings.scene_range[1]);
        step=Math.max(-settings.scene_range[0],step);
        if(settings.scene_range[1]==settings.step_max){
            step=-1;
        }

        if(th.is("[data-step_max]")){
            img_max=intval(th.attr("data-step_max"));
        }
        if(th.is("[data-width]")){
            settings.width=intval(th.attr("data-width"));
        }
        var img_last=-1;
        var handlerIndex;
        var data=th.data("myEye");
        if(data){
            handlerIndex=data;
            _moduleTimeHandler.clear(handlerIndex);
        }
        else{
            handlerIndex=_moduleTimeHandler.getNewIndex();
            th.data("myEye",handlerIndex);
        }
        var dir=1;
        _moduleTimeHandler.set(handlerIndex,function(){
            step+=dir;
            if(step<0){
                dir=1;
            }
            var img=step;
            if(img<0){
                img=0;
            }
            if(img>img_max*2-1){
                img=0;
            }
            else if(img>img_max){
                img=img_max-Math.abs(img-img_max);
            }
            if(step>=step_max){
                dir=-1;
                step_max=mt_rand(settings.scene_range[0],settings.scene_range[1]);
                step=step_max;
            }

            if(img_last!=img){
               img_last=img;
               if(settings.width!=0){
                   th.css("background-position",-(img*settings.width)+"px 0px");
               }
               else{

                   th.attr("data-step",img);
               }

            }
        },settings.interval,function(){
            th.attr("data-step",0);
        });
        return handlerIndex;
    };
    $.fn.qAnim=function(options){
        var settings={
            clips:2,
            width:0,
            interval:50,
            loop:0,
            two_side:0,
            wait_arr:[0,0],
            animArr:[],
            onEnd:function(){},
            onStep:false
        };
        if(typeof options=="object"){
            settings=$.extend(settings,options);
        }
        var th=$(this),step_max=settings.clips,step=-1,dir=1,
        two_side=settings.two_side;
        if(settings.animArr.length){
            step_max=settings.animArr.length-1;
        }

        var handlerIndex;
        var data=th.data("qAnim");
        var set;
        if(data){
            set=$.extend(settings,options);
            handlerIndex=data.index;
            _moduleTimeHandler.clear(handlerIndex);
        }
        else{
            set=$.extend(settings,options);
            handlerIndex=_moduleTimeHandler.getNewIndex();
            data={index:handlerIndex};
            th.data("qAnim",data);
        }
        var step_max_add=mt_rand(set.wait_arr[0],set.wait_arr[1]);
        _moduleTimeHandler.set(handlerIndex,function(){
            step+=dir;
            if(step>=step_max && !set.loop){
                _moduleTimeHandler.clear(handlerIndex);
                set.onEnd();
            }
            else{
                if(two_side){
                    if(step>=step_max){
                        step=step_max+mt_rand(set.wait_arr[0],set.wait_arr[1]);
                        dir=-1;
                    }
                    if(step<=0){
                        step=0;
                        dir=1;
                    }
                }
                else{
                    if(step>step_max+step_max_add){
                        step=0;
                        step_max_add=mt_rand(set.wait_arr[0],set.wait_arr[1]);
                    }

                }



                var clip=step;
                clip=Math.min(clip,step_max);
//                $.qLog(clip+" "+(typeof set.onStep));
                if(set.animArr.length){
                    clip=set.animArr[clip];
                }
                if(set.onStep===false){
                    if(set.width){
                        th.css("background-position",-(clip*set.width)+"px 0px");
                    }
                    else{
                        th.attr("data-step",clip);
                    }
                }
                else{
                    set.onStep(clip);
                }
            }


        },set.interval);
        return handlerIndex;
    };
});
function sendCasinoMess(function_name,args){
    var arg =Array.prototype.slice.call(arguments,0);
    window.parent.postMessage(JSON.stringify(arg), "*");
}
myDialogBox=function(placeWrap,dialogWrap,conf){

    conf=$.extend( {
        "text_arr":[],//{type:"user",text:"bum"}
        "calculateClasses":["textForSayClass"],
        user_arr:{},//{user:{name:'misio',class_arr:[],smoke_end_class}}
        addHtml:1,
        newLinkSign:"||newLine||",
        smoke_end_class:"",
        length_type:"",
        start_after_init:1,
        force_css:{},

        onEnd:function(){},
        onSkip:function(){},
        onStartSay:function(type){},
        onEndSay:function(type){},

        onNextStep:function(type){}

    }, conf);
    this.conf=conf;
    this.main=dialogWrap;//will be override
    this.place=placeWrap;

    this.curTextObj={};
    this.curTextArr=[];



};
myDialogBox.prototype={
    init:function(){
        this.handleText();
        if(this.conf.addHtml){
            this.initView();
        }
        this.textObjField=this.main.find(".contentSayJS");
        if(this.conf.start_after_init){
            this.getNextWholeText();
        }

    },
    start:function(){
        this.getNextWholeText();
    },
    handleText:function(){
        var i,i_max=this.conf.text_arr.length-1,bum,arr,k,obj_new,obj;
        for(i=i_max;i>=0;i-- ){
            bum=this.conf.text_arr[i];
            arr=bum.text.split(this.conf.newLinkSign);
            if(arr.length>1){
                obj=copyObj(bum);
                bum.text=arr[0];
                for(k=1;k<arr.length;k++){
                    obj_new=copyObj(obj);
                    obj_new.text=arr[k];
                    this.conf.text_arr.splice(i+k,0,obj_new);
                }
            }
        }
    },
    initView:function(){
        var html=
            '<div class="sayBox">';
        var type,bum,i,class_arr;
        for(type in this.conf.user_arr){
            bum=this.conf.user_arr[type];
            class_arr=[];
            if(typeof bum.class_arr!="undefined"){
                class_arr=bum.class_arr;
            }
            html+=
                '<div class="upBelt sayBoxJS '+class_arr.join(" ")+'" data-type="'+type+'">'+
                '<div class="up"></div>'+
                '<div class="down"></div>'+
                '<div class="content">'+
                '<div>'+bum.name+'</div>'+
                '</div>'+
                '</div>';
        }

        var classes = copyArray(this.conf.calculateClasses);

        var debug_corners=0;

        html+=
            '<div class="back">';
        if(!debug_corners){
            html+=
                '<div class="bend" data-type="'+this.conf.smoke_end_class+'"></div>';
        }
        else{
            var arr=["bottom_left1","bottom_left2","bottom_right1","bottom_right2",
                "top_left1","top_left2","top_right1","top_right2"];
            arr=["bottom_left1","bottom_right1","top_left1","top_right1"];
            arr=["bottom_left2","bottom_right2","top_left2","top_right2"];
            for(i in arr){
                html+=
                    '<div class="bend" data-type="'+arr[i]+'"></div>';
            }


        }
        html+=
            '<div class="bup"></div>'+
            '<div class="bdown"></div>'+
            '<div class="bleft"></div>'+
            '<div class="bright"></div>'+
            '<div class="bleft"></div>'+
            '<div class="bext"></div>'+
            '</div>'+
            '<div class="content">'+
            '<div class="textBox contentSayJS '+classes.join(' ')+'"></div>'+
            '<div class="textNavi">'+
            '<div class="naviBox skipDialogJS">'+
            '<div class="qtext">'+_LANGS['BUTTON_SKIP']+'</div>'+
            '<div class="naviImg skip"></div>'+
            '</div>'+
            '<div></div>'+
            '<div class="naviBox nextTextJS" style="margin-left: 20px;">'+
            '<div class="qtext">'+_LANGS['BUTTON_NEXT']+'</div>'+
            '<div class="naviImg next"></div>'+
            '</div>'+
            '</div>'+
            '</div>'+
            '</div>';
        var obj=$(html);
        obj.find(".contentSayJS").css(this.conf.force_css);
        if(this.conf.length_type){
            obj.attr("data-type",this.conf.length_type);
        }

        var user_first=this.findFirstUser();
        if(user_first){
            obj.find(".sayBoxJS").hide().filter("[data-type='"+user_first+"']").show();
        }




        this.main.append(obj);
        this.main=obj;
    },
    findFirstUser:function(){
        var i;
        for(i in this.conf.text_arr){
            return this.conf.text_arr[i].type;
        }
        return "";
    },
    hideBox:function(){
        this.main.addClass("hide");
        this.main.find(".nextTextJS").off("click.nextGN");
    },
    remove:function(){
        this.main.remove();
        this.textObjField.letterByletter("clear");
    },
    getNextWholeText:function(){
        var th=this;
        var main=this.main;



        function calculateWordDimensions(){
            var classes = copyArray(th.conf.calculateClasses);
            classes.push('textDimensionCalculation');
            var html="<div class='"+classes.join(' ')+"'></div>";

            this.curElement=$(html);
            if(th.conf.length_type){
                this.curElement.attr("data-type",th.conf.length_type);
            }
            this.curElement.css(th.conf.force_css);
            th.place.append(this.curElement);
        }
        calculateWordDimensions.prototype={
            check:function(text){
                this.curElement.html(text);
                var dimensions = {
                    width : this.curElement.outerWidth(),
                    height : this.curElement.outerHeight()
                };
                return dimensions;
            },
            clear:function(){
                this.curElement.remove();
            }
        };

        var calcObj=new calculateWordDimensions();

        this.curTextObj=this.conf.text_arr.shift();




        main.removeClass("hide");
        main.find(".sayBoxJS").hide().filter("[data-type='"+this.curTextObj.type+"']").show();
        if(typeof this.conf.user_arr[this.curTextObj.type]!="undefined"){
            if(typeof this.conf.user_arr[this.curTextObj.type].smoke_end_class!="undefined"){
                main.find(".bend").attr("data-type",this.conf.user_arr[this.curTextObj.type].smoke_end_class);
            }


        }

        var textArr=this.curTextObj.text.split(" "),textTmp=[],i;
        var textObjField=this.textObjField;

        var textHeight=textObjField.height();

        while(textArr.length>0){
            textTmp=[];
            for(i=0;i<10;i++){
                textTmp.push(textArr.shift());
            }
            while(textArr.length>0 && calcObj.check(textTmp.join(" ")).height<=textHeight){
                textTmp.push(textArr.shift());
            }
            if(calcObj.check(textTmp.join(" ")).height>textHeight){
                textArr.unshift(textTmp.pop());
            }
            this.curTextArr.push(textTmp.join(" "));
        }
        calcObj.clear();
        function nextStep(){
            textObjField.off("lBl_end").off("lBl_start").one("lBl_start",function(){
                th.conf.onStartSay.call(th,th.curTextObj.type);
            }).on("lBl_end",function(){
                th.conf.onEndSay.call(th,th.curTextObj.type);
            }).letterByletter({letterTime:40,text:th.curTextArr.shift()},_moduleTimeHandler);
            th.conf.onNextStep.call(th,th.curTextObj.type);
        }

        nextStep();
        main.find(".skipDialogJS").off("click.skipGN").on("click.skipGN",function(){
            th.conf.onSkip.call(th);
            th.conf.onEnd.call(th);
            th.hideBox();
        });
        main.find(".nextTextJS").off("click.nextGN").on("click.nextGN",function(){
            if(textObjField.letterByletter("isEnd")==1){
                if(th.curTextArr.length>0){
                    nextStep();
                    _mySound.playSound("page_selector");
                }
                else if(th.conf.text_arr.length>0){
                    th.getNextWholeText();
                }
                else{
                    th.conf.onEnd.call(th);
                    th.hideBox();
                }
            }
            else{
                _mySound.playSound("page_selector");
                textObjField.letterByletter("clear");
            }
        });
    }
};
function isMobile(){
    this.storage_key="isMobileGame";
    this.is_mobile=-1;
}
isMobile.prototype={
    takeCustom:function(){
        if(_isMobile){
            return 1;
        }
        return 0;
    },
    take:function(){
        if(this.is_mobile==-1){
            this.is_mobile=_myStorage.get(this.storage_key);
            if(this.is_mobile===null){
                this.is_mobile=this.takeCustom();
            }
            this.is_mobile=intval(this.is_mobile);
        }
        return this.is_mobile;
    },
    set:function(is_mobile){
        this.is_mobile=is_mobile;
        _myStorage.set(this.storage_key,is_mobile);
    }
};
var _isMobileObj=new isMobile();
