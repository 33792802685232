function myEventContainer(){
    this.events_all={};
    this.events_one={};
}
myEventContainer.prototype={
    prepareNameArr:function(name){
        if(name.indexOf(".")==-1){
            return [name,"all"];
        }
        else{
            var bum=name.split(".");
            if(bum[1]=="all"){
                alert("can't use event subname all!");
            }
            return [bum[0],bum[1]];
        }
    },
    setEvent:function(name_str,fun,obj){
        var name_all_arr=name_str.split(","),i,name;
        for(i in name_all_arr){
            name=name_all_arr[i].trim();
            var name_arr=this.prepareNameArr(name);
            if(typeof obj[name_arr[0]]=="undefined"){
                obj[name_arr[0]]={};
            }
            if(typeof obj[name_arr[0]][name_arr[1]]=="undefined"){
                obj[name_arr[0]][name_arr[1]]=[];
            }
            obj[name_arr[0]][name_arr[1]].push(fun);
        }
    },
    deleteEvent:function(name_str,obj){
        var name_all_arr=name_str.split(","),i,name;
        for(i in name_all_arr){
            name=name_all_arr[i].trim();
            var name_arr=this.prepareNameArr(name);
            if(name_arr[1]=="all"){
                if(typeof obj[name_arr[0]]!="undefined"){
                    delete obj[name_arr[0]];
                }
            }
            else{
                if(typeof obj[name_arr[0]]!="undefined"){
                    if(typeof obj[name_arr[0]][name_arr[1]]!="undefined"){
                        delete obj[name_arr[0]][name_arr[1]];
                    }
                }
            }
        }
        
    },
    exeEvent:function(name,val,obj){
        var name_arr=this.prepareNameArr(name);
        if(typeof obj[name_arr[0]]!="undefined"){
            if(name_arr[1]=="all"){
                var i,k;
                for(i in obj[name_arr[0]]){
                    for(k in obj[name_arr[0]][i]){
                        obj[name_arr[0]][i][k](val);
                    }
                }
            }
            else{
                if(typeof obj[name_arr[0]][name_arr[1]]!="undefined"){
                    for(i in obj[name_arr[0]][name_arr[1]]){
                        obj[name_arr[0]][name_arr[1]][i](val);
                    } 
                }

            }
        }
    },
    on:function(name,fun){
        this.setEvent(name,fun,this.events_all);
        return this;
    },
    one:function(name,fun){
        this.setEvent(name,fun,this.events_one);
        return this;
    },
    off:function(name){
        this.deleteEvent(name,this.events_all);
        this.deleteEvent(name,this.events_one);
        return this;
    },
    clear:function(){
        this.events_all={};
        this.events_one={};
    },
    trigger:function(name,val){
        this.exeEvent(name,val,this.events_one);
        this.deleteEvent(name,this.events_one);
        this.exeEvent(name,val,this.events_all);
    }
    
};
function myEventHandler(){
    this.set=[];
}
myEventHandler.prototype={
    pushContainer:function(cont){
        this.set.push(cont);
    },
    trigger:function(name,val){
        var i;
        for(i in this.set){
            this.set[i].trigger(name,val);
        }
    }
};
var _moduleEvents=new myEventContainer();
var _externalEvents= new myEventContainer();
var _eventHandler= new myEventHandler();
_eventHandler.pushContainer(_moduleEvents);
_eventHandler.pushContainer(_externalEvents);
