function intval(number){
    number=parseInt(number);
    if(isNaN(number)){
        number=0;
    }
    return number;
}
function arrayAreEqual(arr1,arr2){
    if(arr1.length!=arr2.length){
        return false;
    }
    var i;
    for(i in arr1){
        if(!in_array(arr1[i],arr2)){
            return false;
        }
    }
    for(i in arr2){
        if(!in_array(arr2[i],arr1)){
            return false;
        }
    }
    return true;
}
function compareTwoObj(obj,obj2){
    var i,bum,qtype;
    for(i in obj){
        bum=obj[i];
        qtype=typeof bum;
        if(qtype!= typeof obj2[i]){
            return false;
        }
        else if(qtype=="number" || qtype=="string"){
            if(obj[i]!=obj2[i]){
                return false;
            }
        }

        else if(qtype=="object"){
            if(!compareTwoObj(obj[i],obj2[i])){
                return false;
            }
        }
    }
    for(i in obj2){
        if(typeof obj[i]=="undefined"){
            return false;
        }
    }
    return true;
}
function array_shuffle(arr){
    var i;
    for (i = arr.length-1; i >=0; i--) {

        var randomIndex = Math.floor(Math.random()*(i+1));
        var itemAtIndex = arr[randomIndex];

        arr[randomIndex] = arr[i];
        arr[i] = itemAtIndex;
    }
}  
function mod(a,b)
{
    return (((a % b) + b) % b);
}
function getRandomArrayElements(arr, count) {
    if(arr.length<count){
        return arr;
    }
    var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
    while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
    }
    return shuffled.slice(min);
}
function myRemoveByValue(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

function textToInt(text){
    if(typeof text=="string"){
        text=text.replace(/\s+/g, '');
    }
    return intval(text);
}
function mt_rand(min,max){
    var dle=max-min+0.5;
    dle=Math.floor(Math.random()*(dle+0.5))+min;
    dle=Math.min(dle,max);
    return dle;
}
function debugArr(arr){
    alert(JSON.stringify(arr));
}
function maxArr(arr){
    var i,val,max=false;
    for(i in arr){
        val=arr[i];
        if(max===false || max<val){
            max=val;
        }
    }
    return max;
}
function minArr(arr){
    var i,val,max=false;
    for(i in arr){
        val=arr[i];
        if(max===false || max>val){
            max=val;
        }
    }
    return max;
}
function mySortArr(arr,key,sort_desc){
    return arr.sort(function(a, b) {
        var x = a[key];
        var y = b[key];

        if (typeof x == "string")
        {
            x = x.toLowerCase();
        }
        if (typeof y == "string")
        {
            y = y.toLowerCase();
        }
        var res= ((x < y) ? -1 : ((x > y) ? 1 : 0));
        if(sort_desc){
            return -res;
        }
        return res;
    });
}
function testHowlong(fun){
    $(function(){
        var old=new Date().getTime();
        fun();
        $.qLog(new Date().getTime()-old);
    });
}
function getIdFromRange(value, min, max, howmany_id) {
    value = Math.min(value, max);
    value = Math.max(value, min);
    if (howmany_id == 1) {
        return 1;
    }
    if (min == max) {
        return howmany_id;
    }
    value-=min;
    max-=min;

    var inc = max / howmany_id;
    var id = Math.round((value - inc / 2) / inc) + 1;
    id = Math.min(howmany_id, id);
    id = Math.max(1, id);
    return id;
}
function getValFromRange(have,val_min,val_max,range_min,range_max){
    if(val_min==val_max || have==range_max){
        return val_max;
    }
    var val=val_min+getIdFromRange(have,range_min,range_max,val_max-val_min)-1;
    return val;
}
var startTimeServer=Math.ceil(intval(__beginCurrentTime)-(new Date()).getTime()/1000);
function getCurrentTime(){
    return (Math.floor(new Date().getTime()/1000)+startTimeServer);
}

function handleAjaxRes(obj){
    handleAjaxResMain(obj);
    return obj.ajaxData;
}
var _scaleObj={space:0,body:[],full_min:[1318,914],scale:1};
$(function(){
    $.takeScalePX=function(){
        return _scaleObj.space;
    };
    $.takeScaleObj=function(){
        return copyObj(_scaleObj);
    };
    $.getCurrentTime=function(){
        return getCurrentTime();
    };
    $.getStartTimeServer=function(){
        return startTimeServer;
    }
    $.updateServerTime=function(time){
        var test_time=Math.ceil(intval(time)-(new Date()).getTime()/1000);
        startTimeServer=Math.max(startTimeServer,test_time);
    };

    var _isIpad=0;
    $(document).on("touchmove",function(){
        _isIpad=1;
    });
    $.isIpad=function(){
        return _isIpad;
    };
    $.getXevent=function(e){
        if($.isIpad()){
           var orig = e.originalEvent;
           return intval(orig.changedTouches[0].pageX);
        }
        else{
           return intval(e.pageX);
        }
    };
    $.getYevent=function(e){
        if($.isIpad()){
            var orig = e.originalEvent;
            return intval(orig.changedTouches[0].pageY);
        }
        else{
            return intval(e.pageY);
        }
    };
     $.getXeventOffset=function(e){
        if($.isIpad() && typeof e.touches!="undefined" && e.touches.length == 1){


            var touch = e.touches[0]; // Get the information for finger #1
            var off=$(touch.target).offset(),scale_top=1;
            var offObj=$.takeScaleObj();
            scale_top=1/offObj.scale;
            return (touch.pageX-off.left)*scale_top;



        }
        else{
           if (typeof e.offsetX!="undefined") {
                return e.offsetX;
            }
            else if (e.layerX) {
                return e.layerX;
            }
        }
    };
    $.getYeventOffset=function(e){
        if($.isIpad() && typeof e.touches!="undefined"){
            var touch = e.touches[0]; // Get the information for finger #1
            var off=$(touch.target).offset(),scale_top=1;
            var offObj=$.takeScaleObj();
            scale_top=1/offObj.scale;
            return (touch.pageY-off.top)*scale_top;

        }
        else{
            if (typeof e.offsetY!="undefined") {
                return e.offsetY;
            }
            else if (e.layerY) {
                return e.layerY;
            }

        }
    };
    $.fn.myLoad = function(arr) {
        var th = $(this), i, length = arr.length, loaded = 0;
        for (i in arr) {
            //myLoad
            $('<img/>').one("one load",function() {
                $(this).remove();
                loaded++;
                if (loaded == length) {
                    th.trigger("myload");
                }
            }).attr('src', arr[i]);
        }
        return th;
    };
    $.fn.myCenterV=function(){
        return $(this).each(function(){
            var th=$(this);
            if(th.is(":visible")){
                if(th.hasClass('relative')){
                    th.css({"position":"relative","top":"50%",
                "margin-top":-th.outerHeight()/2+"px"}).attr("tcsetv","1");
                }
                else{
                    th.css({"position":"absolute","top":"50%",
                "margin-top":-th.outerHeight()/2+"px"}).attr("tcsetv","1");
                }
            }


        });
    };
    $.fn.myCenterH=function(){
        return $(this).each(function(){
            var th=$(this),pos;
            if(th.is(":visible")){
                if(th.hasClass('relative')){
                    pos="relative";
                }
                else{
                    pos="absolute"
                }
                th.css({"position":pos,"left":"50%",
                "margin-left":"-"+th.outerWidth()/2+"px"
                }).attr("tcset","1")
            }

        });
    };
    $.fn.myCenter=function(){
        return $(this).each(function(){
            var th=$(this),pos;
            if(th.is(":visible:not(.nottocenter)")){
                if(th.hasClass('relative')){
                    pos="relative";
                }
                else{
                    pos="absolute";
                }
                th.css({top:"auto",left:"auto","margin-left":0,"margin-top":0});
                th.css({"position":pos,"top":"50%","left":"50%",
                "margin-left":"-"+th.outerWidth()/2+"px",
                "margin-top":-th.outerHeight()/2+"px"}).attr("tcset","1")
            }

        });
    };
    $.fn.loginBox=function(){
        return this.each(function(){
            var th=$(this),main=th,input=th.find("[type='text']"),err=th.find(".error"),succ=th.find(".succ"),texts=[],tmp=th.find(".texts>div"),
            index=_moduleTimeHandler.getNewIndex(),i,bum,text;
            for(i=0;i<tmp.length;i++){
                bum=tmp.eq(i);
                texts[intval(bum.attr("mode"))]=bum.html();
            }
            input.on("keyup",function(){
                text=input.val();
                var text_range=[3,15];
                var patt = /^[むa-z0-9ぁ-んァ-ン_\-]+$/i,text_cur=text;
                if(patt.test(text_cur) && text_cur.length>=text_range[0] && text_cur.length<=text_range[1]){
                    err.hide();
                    succ.show();
                    _moduleTimeHandler.setOne(index, function(){
                        $.mypost("/ajax/logincheck",
                            {"clogin":text_cur},
                            function(res){
                                var data=handleAjaxRes(res);
                                if(text==data.login){
                                    var mode;
                                    if(data.can){
                                        mode=0;

                                    }
                                    else{
                                        mode=data.mode;
                                    }
                                    main.trigger("modeLogin",mode);
                                }
                            },
                            "json"
                        );
                    }, 300);
                }
                else{
                    bum=1;
                    if(text_cur.length<text_range[0]){
                        bum=3;
                    }
                    else if(text_cur.length>text_range[1]){
                        bum=4;
                    }
                    main.trigger("modeLogin",bum);
                }
            });


        });
    };
    $.qLog=function(html){
        $("#qLOG").show().find(".cont").html($("#qLOG .cont").html()+html);
    };
    $("#qLOG #closeL").click(function(){
        $("#qLOG .cont").html("");
         $("#qLOG").hide();
    });
    $.fn.myScale=function(scale){
       var th_all=this;
       th_all.each(function(){
           var scale_abs=Math.abs(scale),th=$(this),width,height,width_oryginal=th.outerWidth(true),height_oryginal=th.outerHeight(true),par=th.parent(".myScale");
           width=Math.round(width_oryginal*scale_abs);
           height=Math.round(height_oryginal*scale_abs);
           if(!par.length){
               par=th.wrap("<div class='myScale'></div>").closest(".myScale");
           }
           par.css({width:width,height:height});
           th.css({"position":"absolute","top":-(height_oryginal-height)/2,"left":-(width_oryginal-width)/2,
           "-ms-transform":"scale("+scale+"," +scale_abs+")",
            "-webkit-transform":"scale("+scale+"," +scale_abs+")",
        "transform":"scale("+scale+"," +scale_abs+")"});
       });
       return th_all;
   };
   $.fn.qScale=function(scale_x,scale_y){
       return $(this).css({
           "-ms-transform":"scale("+scale_x+"," +scale_y+")",
            "-webkit-transform":"scale("+scale_x+"," +scale_y+")",
        "transform":"scale("+scale_x+"," +scale_y+")"});
   };
    $.fn.flagBox=function(){
        return this.each(function(){
            var th=$(this);
            th.off("click.ffBox").on("click.ffBox",function(){
                var box=th.find(".fwin");
                if(box.is(":visible")){
                    box.hide();

                }
                else{
                    $("body").on("click.flagBox",function(e){
                       if(!$.contains(th[0],$(e.target)[0])){
                           box.hide();
                           $("body").off("click.flagBox");
                       }
                    });
                    box.show();
                }
            });
        });
    };
    $.fn.mySmoke=function(){
        var last=0,smoke=$("#smoke");
        function setPos(ev){
            var pos={left:$.getXevent(ev),top:$.getYevent(ev)},smoke_size=[smoke.width(),smoke.height()],
            body=[$("body").width(),$("body").height()];
            if(pos.left==0 && pos.top==0){
                return false;
            }
            var left=pos.left+15,top=pos.top+15;
            if(left+smoke_size[0]>body[0]){
                left=body[0]-smoke_size[0];
            }
            if(top+smoke_size[1]>body[1]){
                top=body[1]-smoke_size[1];
            }

//            $.qLog($("window").scrollTop());
            smoke.css({'left': left,'top':top});
        }
        function prepareContent(th){
            var sm=th.find(".mysmoke");
            if(sm.html()!=""){
                if(th.find('.mysmoke_var').length==1 && !(sm.is('.blocked'))){
                    var smoke_var=th.find('.mysmoke_var').html(),content=th.children('.mysmoke').html();
//                    alert("real "+content);
                    var sign="|#|",tmp,tmp2;
//                    content=$(content).find(".smoke_var").remove().end();
                    content=$('<div>'+content+"</div>").find(".mysmoke_var").remove().end().html();

                    if(content.lastIndexOf("|#|")==-1){
                        sign="#";
                    }
//                    $.qLog(content+" "+sign+" L");

//                    tmp=content.split("|#|");
//                    if(tmp.length==1){
//                        tmp=content.split("#");
//                        debugArr(tmp);
//                        tmp2=smoke_var.split("#");
//                        $.qLog(tmp.length+"_"+tmp2.length+" "+tmp2+"; ")
//                        if(tmp.length>1 && (tmp.length-1==tmp2.length)){
//                            sign="#";
//                        }
//                    }
                    var obj=smoke_var.split(sign);
                    $('#smoke .content').html(connectVars(th.children('.mysmoke').html(),obj,sign));
                    smoke.show();
                }
                else if(!(sm.is('.blocked'))){
                    $('#smoke .content').html(th.children('.mysmoke').html());
                    smoke.show();
                }
//                th.trigger("mousemove");
            }
        }




        return this.each(function(){

            $(this).find(".mysmoke:not([smoke_set])").attr("smoke_set","1").parent().
            on("mouseover",function(){
                if(!$.isIpad()){
                    prepareContent($(this));
                }
            }).on("mouseout mouseup",function(){
                if(!$.isIpad()){
                    smoke.hide();
                }
            }).mousemove(function(e){
                if(!$.isIpad()){
                    setPos(e);
                }
            }).on("touchmove",function(e){
                if(!last){
                    var th=$(this);
                    last=setTimeout(function(){
                        prepareContent(th);
                    },600);
                }
                setPos(e);
            }).on('touchend',function(){
                if(last){
                    clearTimeout(last);
                    last=0;
                }
                smoke.hide();
            });
        });
    };
    checkCookie();
    function checkCookie(){
        var user=intval(_myStorage.get("check_c"));
        if (user){
            $("#cookies_info").hide();
        }
        else{
            $("#cookies_info .close,#cookies_info .acceptClose").click(function(){
                _myStorage.set("check_c",1);
                $("#cookies_info").hide();
            });
        }
    }
});
function dateFormat(time,format_id){
    var obj= new Date(time*1000),text,mon=obj.getMonth()+1,day=obj.getDate(),
    hour=obj.getHours(),min=obj.getMinutes(),sec=obj.getSeconds();
    if(mon<10){
        mon="0"+mon;
    }
    if(day<10){
        day="0"+day;
    }
    if(hour<10){
        hour="0"+hour;
    }
    if(min<10){
        min="0"+min;
    }
    if(sec<10){
        sec="0"+sec;
    }
    if(format_id=="chat"){
        text=hour+":"+min;
    }
    else{
        text=obj.getFullYear()+"-"+mon+"-"+day+" "+
        hour+":"+min+":"+sec;
    }


    return text;
     //return dateFormat(time, "yyyy-mm-dd HH:MM:ss");
}
function timeFormat(time,format){
    time=intval(time);
    var show;
    if(time>0){
        var dni=Math.floor(time/24/60/60);
        var godzin	= Math.floor((time-dni*86400)/60/60);
        var minut	= Math.floor((time-dni*86400-godzin*3600)/60);
        var sekund	= Math.floor(time-dni*86400-godzin*3600-minut*60);
        var p_dni=dni;
        var p_godzin=godzin;
        var p_minut=minut;
        var p_sekund=sekund;
        if(sekund<10){p_sekund = '0'+sekund;}
        if(minut<10){p_minut = '0'+minut;}
        if(godzin<10){p_godzin = '0'+godzin;}
//        return p_dni+" "+p_godzin+':'+p_minut+':'+p_sekund+_METRIC.HOURS_SHORT;
        if(!format){
            if(dni>30){
                 show = p_dni+_METRIC.DAYS;
            }
            else if(dni>0){
                if(godzin>0){
                    show = p_dni+_METRIC.DAYS+" "+godzin+_METRIC.HOURS_SHORT;
                }
                else{
                    show = p_dni+_METRIC.DAYS;
                }
            }
            else if(godzin>0){show = p_godzin+':'+p_minut+':'+p_sekund;}
            else{show = p_minut+':'+p_sekund;}
        }
        else if(format==1){
            if(dni>30){
                 show = p_dni+_METRIC.DAYS;
            }
            else if(dni>0){
                if(godzin>0){
                    show = p_dni+_METRIC.DAYS+" "+godzin+_METRIC.HOURS_SHORT;
                }
                else{
                    show = p_dni+_METRIC.DAYS;
                }
            }
            else if(godzin>0){show = godzin+':'+p_minut+_METRIC.HOURS_SHORT}
            else{show = minut+':'+p_sekund+_METRIC.MINUTES_SHORT;}
        }

     }
     else{
         show="00:00";
     }
     return show;
}
function in_array (needle, haystack) {
    var key = '';
    for (key in haystack) {
    	if (haystack[key] == needle){
            return true;
        }
    }
    return false;
}
function copyObj(obj){
    return $.extend(true, {}, obj);
}

function copyArray(arr){
    return arr.slice(0);
}

function copyArrayDim(arr){
   var i, copy;

    if(Array.isArray(arr)){
        copy = arr.slice(0);
        for(i=0;i<copy.length;i++){
            copy[i]=copyArrayDim(copy[i]);
        }
        return copy;
    } else if( typeof arr === 'object' ) {
        throw 'array with object';
    } else {
        return arr;
    }
}
function afterInit(){
    this.isLoaded=0;
    this.queue_fun=[];
}
afterInit.prototype={
    addFun:function(callback){
        if(this.isLoaded){
            callback.apply();
        }
        else{
            this.queue_fun.push(callback);
        }
    },
    wasLoaded:function(){
        return this.isLoaded;
    },
    load:function(){
        this.isLoaded=1;
        var bum;
        while(this.queue_fun.length>0){
            bum=this.queue_fun.pop();
            bum.apply();
        }
    }

};

function timeHandler() {
    this.arr=[];
    this.arr_end=[];
    this.arr_clear=[];
    this.queue_fun=[];
    this.blocked=0;
}
timeHandler.prototype={
    clear: function(index,cant_block){
        if(!cant_block && this.blocked){
            return false;
        }
        if(typeof index=="undefined"){
            return false;
        }
        clearTimeout(this.arr[index]);
        this.arr[index]=null;
        if(typeof this.arr_end[index]==="function"){
            this.arr_end[index].apply();
            this.arr_end[index]=null;
        }
        if(typeof this.arr_clear[index]==="function"){
            this.arr_clear[index].apply();
            this.arr_clear[index]=null;
        }
        if(typeof  this.queue_fun[index]!="undefined"){
            this.queue_fun[index]=[];
        }

        return this.arr[index];
    },
    clear_all:function(){
        this.blocked=1;
        var i;
        for(i=this.arr.length-1;i>=0;i--){
            this.clear(i,1);
        }
        this.arr=[];
        this.arr_end=[];
        this.queue_fun=[];
        this.blocked=0;
    },
    queueLength:function(index){
        return this.queue_fun[index].length;
    },
    queueAdd:function(index,callback){
      if(typeof this.queue_fun[index]!="undefined"){
          this.queue_fun[index].push(callback);
      }
      else{
          this.queue_fun[index]=[callback];
      }
      return this;
    },
    queueTrigger:function(index){
        if(typeof this.queue_fun[index]!="undefined" && this.queue_fun[index].length>0){
            var fun=(this.queue_fun[index]).shift();
            fun();
        }
        return this;
    },
    isIndexSet:function(index){
        if(typeof this.arr[index]=="undefined"){
            return false;
        }
        if(this.arr[index]!==null){
            return true;
        }
        return false;
    },
    getNewIndex:function(){
        var len=this.arr.length;
        this.arr[this.arr.length]=null;
        return len;
    },
    setOne:function(index,callback,interval,callback_end,onClear){
        if(this.blocked){
            return false;
        }
        if(typeof index=="undefined"){
            return null;
        }
        clearTimeout(this.arr[index]);
        if(typeof callback_end==="function"){
            this.arr_end[index]=callback_end;
        }
        else{
            this.arr_end[index]=null;
        }

        if(typeof onClear==="function"){
            this.arr_clear[index]=onClear;
        }
        else{
            this.arr_clear[index]=null;
        }



        var thG=this;
        this.arr[index]=setTimeout(
           function(){
                thG.arr[index]=null;
                callback();
                if(typeof callback_end==="function"){
                    thG.arr_end[index]=null;
                    callback_end();
                }

           }
        ,interval);
        return index;
    },
    addOne:function(callback,interval,callback_end,onClear){
        return this.setOne(this.arr.length,callback,interval,callback_end,onClear);
    },
    add:function(callback,interval,callback_end){
        return this.set(this.arr.length, callback,interval,callback_end);
    },
    set:function(index,callback,interval,callback_end){
        if(this.blocked){
            return false;
        }
        if(typeof index=="undefined"){
            return null;
        }
        clearTimeout(this.arr[index]);
        if(typeof callback_end==="function"){
            this.arr_end[index]=callback_end;
        }
        else{
            this.arr_end[index]=null;
        }
        this.loop(index,callback,interval);
        return index;
    },
    loop:function(index,callback,interval){
        var obj=this;
        this.arr[index]=setTimeout(function(){
            obj.loop(index,callback,interval);
        },interval);
        callback();
    }
};
var _moduleTimeHandler= new timeHandler();
var _eventTimeHandler= new timeHandler();
var _externalTimeHandler= new timeHandler();

function manageTimers(timers_arr){
    var i,bum;
    for(i in timers_arr){
        bum=timers_arr[i];
        prepareTimer(bum.div_name,bum.time_end,bum.time_start,bum.noend,bum.vars,bum.delete_on_module_load);
    }
}
function myStorage(){};

myStorage.prototype={
    set:function(key,value){
        try{
            localStorage.setItem(key, value);
        }
        catch(e){}
    },
    del:function(key){
        try{
            localStorage.removeItem(key);
        }
        catch(e){}
    },
    setJson:function(key,arr){
        try{
            localStorage.setItem(key, JSON.stringify(arr));
        }
        catch(e){}
    },
    getJson:function(key){
        try{
            return JSON.parse(localStorage.getItem(key));
        }
        catch(e){
            return null;
        }

    },
    get:function(key){
        try{
            return localStorage.getItem(key);
        }
        catch(e){
            return null;
        }
    }
};
var _myStorage=new myStorage();

var _googleFontAfterInit=new afterInit();


function myConstruct(constructor, args) {
    function F() {
        return constructor.apply(this, args);
    }
    F.prototype = constructor.prototype;
    return new F();
}
function myExtend (base, constructor) {
  var prototype = new Function();
  prototype.prototype = base.prototype;
  constructor.prototype = new prototype();
  constructor.prototype.constructor = constructor;
}
