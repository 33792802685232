
var _PopUpQueue=[],_PopUpRemember=[],_PopUpStatic={};
var _DEBUG_CONSOLE=0;
function addToMainPopup(html,show_next,add_class,to_js,module_page){
    if(!module_page){
        module_page="";
    }
    if(!add_class){
        add_class="";
    }
    html="<div class='popupWinBig "+add_class+"'>"+html+"</div>";
    if(module_page){
        clearFromArrModules();
    }
    _PopUpQueue.push({html:html,to_js:to_js,module_page:module_page});

    if(show_next){
        gameCoverNext(1);
    }
}
function clearFromArrModules(){
    var i;
    for (i = _PopUpQueue.length - 1; i >= 0; i--) {
        if (_PopUpQueue[i].module_page) {
            _PopUpQueue.splice(i, 1);
        }
    }
}
function manageGameEvents(arr){
    var i,bum,gameCover=0;
    for(i in arr){
        bum=arr[i];
        if(bum.static==0){
            if(bum.type_display=="module" || bum.type_display=="template"){
                var to_js=false;
                if(typeof bum.to_js!="undefined" && bum.to_js){
                    to_js=copyObj(bum.to_js);
                }
                addToMainPopup(bum.html,0,"",to_js);

                if(typeof bum.show_later=="undefined"){
                    gameCover=1;
                }
                else if(intval(bum.show_later) && gameCover==0){
                    gameCover=-1;
                }

            }
            if(bum.type_display=="js"){
                useGameJS(bum.type,bum.html);
            }
        }
        else{
           _PopUpStatic[bum.type]=copyObj(bum);
        }
    }
    if(gameCover==1 || (gameCover==0 && $("#myGameCoverQueue>div").length)){
        gameCoverNext(1);
    }
    //$("#myGameCoverQueue>div")
}
function useGameJS(type,vars){
    if(type=="addenemy"){
        vars=JSON.parse(vars);
    }
}
function showCoverStatic(type,to_js){
    var win=$("#myGameCover"),
    content=win.find("#myGameCoverWindow");
    win.show();
    var coverObj=copyObj(_PopUpStatic[type]);
    if(typeof coverObj.to_js!="undefined"){
        coverObj.to_js={};
    }
    if(to_js){
        coverObj.to_js=$.extend(coverObj.to_js,to_js);
    }
    content.html(coverObj.html);
    var key,val;
    for(key in coverObj.to_js){
        val=coverObj.to_js[key];
        content.find(".putDataJS[data-put='"+key+"']").html(val);
    }
    showGameCover(content,coverObj.to_js,"");
}
function gameCoverNext(is_start,not_open_next,showOld){

    var win=$("#myGameCover"),win_is_visible=win.is(":visible"),
    content=win.find("#myGameCoverWindow");
    if(is_start && win_is_visible){
        //is was module popup
        if(win.find(".popupWinBig.popup:visible").length==0){
            return false;
        }
    }
    var pages_without_popup=[];
    if(in_array(getCurrentPage(),pages_without_popup)){
        return false;
    }
    if((_PopUpQueue.length || (_PopUpRemember.length && showOld)) && !not_open_next){
        win.show();


        var coverObj;
        if((_PopUpRemember.length && showOld)){
            coverObj=_PopUpRemember.shift();
            _PopUpRemember.pop();
            _PopUpRemember.push(coverObj);

        }
        else{
            coverObj=_PopUpQueue.shift();
            if((_PopUpRemember.length==0 && coverObj.html.indexOf("closeCoverBack")==-1) || _PopUpRemember.length>0){
                _PopUpRemember.push(coverObj);
            }

        }
        _eventTimeHandler.clear_all();


        content.html(coverObj.html);
        showGameCover(content,coverObj.to_js,coverObj.module_page);
    }
    else{
        _PopUpRemember=[];
        _eventTimeHandler.clear_all();
        var pageCover=content.find(".popupWinBig>.gameCoverContent").attr("data-page");

        win.hide();
        _eventHandler.trigger("hideCover",pageCover);
        content.html("");
    }
    return true;
}
function showGameCover(obj,to_js,module_page){


    _eventTimeHandler.clear_all();

    afterLoadModule(obj);
    if(obj.find(".closeCover.main").length==0){
        obj.append('<div class="closeCover main"></div>');
    }
    var row=obj.find(".gameCoverContent"),page=row.attr("data-page");

    var page_fn=page;
    console.log(page);
    page_fn=page_fn.replace(/\./g,"_");
    var fun_prefix="ev_";
    if(module_page){
        fun_prefix="init_";
        obj.attr("data-is_module",1);
    }
    else{
        obj.attr("data-is_module",0);
    }
    if (eval("typeof " + fun_prefix + page_fn + " == 'function'")) {
        var fn = window[fun_prefix + page_fn];
        fn(to_js);
    }



    obj.myCenter();

    obj.find(".textfill").textfill();

    obj.find(".closeCover").off("click.gameCoverClose").on("click.gameCoverClose",function(){
        var notShowNext=0,th=$(this),showBack=0;
        if(th.is(":not(.main)") && th.is(":not(.noSound)")){
            _mySound.playSound("button_click");
        }
        if(th.is(".myLink")){
            notShowNext=1;
        }
        else if(th.is(".button,.myImage")){
            if(th.closest(".formXHR").length){
                notShowNext=1;
            }
        }
        if(module_page){
            setCurrentPopup("",0);
            prepareQuest();
            _eventHandler.trigger("modulePopupClose");
        }
        if(th.is(".closeCoverBack")){
            showBack=1;
        }
        gameCoverNext(0,notShowNext,showBack);

    });
    if(intval(row.attr("data-closeit"))){
        row.find(".closeCover").trigger("click");
    }
}
function initGame(data){
    if(typeof data.init_skill_stat!="undefined"){
        _SkillSet.init(data.init_skill_stat,data.init_skill_set);
    }
    if(typeof data.init_gifts!="undefined"){
        _myGifts.init(data.init_gifts);
    }
    if(typeof data.init_cash_set!="undefined"){
        //init_cash_stat
        //init_cash_stat
        _cashObjSet.init(data.init_cash_stat,data.init_cash_set);
    }
    if(typeof data.init_girl_exp!="undefined"){
        _GIRL_EXP=data.init_girl_exp;
    }
}
function handleAjaxResMain(data){
    if(typeof data.profileData!="undefined"){
        _myProfile.updateData(data.profileData);
    }
    handleResp(data);
}
function handleResp(data){
    if(typeof data.userArrData !="undefined"){
        _userArr=$.extend(_userArr,data.userArrData);
    }
    if(typeof data.module_popup!="undefined"){
        var toFunVar="";
        if(typeof data.jsVarSendToInit!="undefined"){
            toFunVar=data.jsVarSendToInit;
        }
        if(!_ON_DEV){
            ga('send', 'pageview',data.page);
        }
        setCurrentPopup(data.page,data.page_p);
        addToMainPopup(data.module_popup,1,"popup",toFunVar,data.page);
    }

    if(typeof data.gameevents != "undefined"){
        manageGameEvents(data.gameevents);
    }


    if(typeof data.setSkill!="undefined"){
        _SkillSet.updateSet(data.setSkill);
    }
    if(typeof data.setCashData !="undefined"){
        _cashObjSet.updateSet(data.setCashData);
    }

    if(typeof data.quickDebug!="undefined"){
        alert(data.quickDebug);
    }
    if(typeof data.pageReload!="undefined"){
        window.location.href=data.pageReload;
    }
    if (typeof data.quest != "undefined") {
        prepareQuest(data.quest.q_id, data.quest.vars);
    }
    if(typeof data.play_sound!="undefined"){
        _mySound.playSound(data.play_sound);
    }
    if(typeof data.closePopupPage!="undefined"){
        clearFromArrModules();
        gameCoverNext();
    }
    if(typeof data.packageArr!="undefined"){
        managePackage(data.packageArr);
    }
    if(typeof data.debugSql!="undefined"){
        if(_DEBUG_CONSOLE){
            console.log(data.debugSql);
        }
    }
}
function managePackage(premium){
    var p_id,added=0,bum;
    if(typeof premium.user!="undefined"){
        for(p_id in premium.user){
            bum=premium['user'][p_id];
            $.iconPremiumTimer("addPremium",bum["type_id"],bum["time_end"],bum.premium,bum.p_id);
        }
        added=1;
    }
    if(typeof premium['static']!="undefined"){
        for(p_id in premium['static']){
            bum=premium['static'][p_id];
            $.iconPremiumTimer("addPremium",bum.type_id,bum.time_end,bum.premium);
        }
        added=1;
    }
    if(added){
        $.iconPremiumTimer("open");
    }
}
function endXHR(data){
    $.reWriteUpdatePanel();
    var moduleContent=$("#content_main");
    initGame(data);

    if(typeof data.profileData!="undefined"){
        _myProfile.updateData(data.profileData);
    }
    if(typeof data.module!="undefined"){
        _eventHandler.trigger("moduleClear");

        if(typeof data.page!="undefined"){
            if(typeof data.page_p!="undefined"){
                setCurrentPage(data.page,data.page_p);
            }
            else{
                setCurrentPage(data.page);
            }

        }
        _moduleTimeHandler.clear_all();
        _moduleEvents.clear();
        _timerManager.onModuleLoad();
        moduleContent.html("").append(data.module);
        moduleContent.handleXHR();
        afterLoadModule(moduleContent);
        var popup=$("#myGameCoverWindow");
        if(popup.is(":visible") && intval(popup.attr("data-is_module"))){
            popup.find(".closeCover").eq(0).trigger("click");
        }
    }

    handleResp(data);



    if(typeof data.hashJS!="undefined"){
        moduleContent.find("#JSNewHash").remove();
        moduleContent.append('<script id="JSNewHash" type="text/javascript">'+data.hashJS+'</script>');
    }
    if(typeof data.module!="undefined"){
        var page=getCurrentPage();
        var page_fn=page;
        if(!_ON_DEV){
            ga('send', 'pageview',page);
        }


        page_fn=page_fn.replace(/\./g,"_");
        if (eval("typeof " + "init_" + page_fn + " == 'function'")) {
            var fn = window["init_" + page_fn];
            var toFunVar="";
            if(typeof data.jsVarSendToInit!="undefined"){
                toFunVar=data.jsVarSendToInit;
            }
            fn(toFunVar);
        }
        moduleContent.one("myPreloaderEnd",function(){

        }).myPreloader({},_externalTimeHandler);
    }
    if(typeof data.time_real!="undefined"){
        $.updateServerTime(data.time_real);
    }


   _semaphoreXHR.tryClear();

    $(".showCurDate:not([set])").attr("set", 1).realDate();
    if(typeof data.goToLoc!="undefined"){
        $.goToLocation(data.goToLoc.action,data.goToLoc.post_arr);
    }
    _timerManager.alltimers();
}

function afterLoadModule(obj){
    prepareSureMess(obj);
    obj.handleXHR();

    obj.find(".moduleArrow").myUpDown();



    obj.find(".tocenter").myCenter();
    obj.find(".tocenterH").myCenterH();
    obj.find(".tocenterV").myCenterV();

    obj.find(".place").manageBook();
//    obj.mySmoke();
    obj.find(".textfill").textfill();

}
function prepareSureMess(obj){
    obj.find(".askSure").unbind("click.askSure").bindFirst("click.askSure",function(e){
        var th=$(this),place=th.closest(".place"),
        show_old_g_id=0;
        place.find(".book[g_id='-2']").remove();
        if(place.find(".book:visible").length){
            show_old_g_id=intval(place.find(".book:visible").eq(0).attr("g_id"));
        }
        var html="",tmp;
        if(th.find(".askSureText").length){
            tmp=th.find(".askSureText");
            html=tmp.html();
            if(tmp.find(".askSureTextVar").length){
                html=connectVars(html,tmp.find(".askSureTextVar").html().split("#"),"#");
            }
        }
        var book=place.addBook($("#bookContentStatic .book[g_id='-2']").clone());
        var quest=book.find(".question");
        quest.hide();
        if(html){
            quest.eq(1).show().html(html);
        }
        else{
            quest.eq(0).show();
        }
        var button=book.find(".sureForm .button.normal");
        button.on("click",function(ev){
            book.bookUse("hide");
            var obj=$(this),form=obj.closest(".sureForm");
            if(intval(obj.attr("data-ans"))==1){
                th.unbind("click.askSure").trigger("click");
            }
            ev.stopImmediatePropagation();
        });

        book.one("close",function(){
            if(show_old_g_id){
                place.find(".book[g_id='"+show_old_g_id+"']").bookUse("show");
            }
        });
        e.stopImmediatePropagation();
        return false;
    });
}
$(function(){
//    $("body").mySmoke();
    function sendXHR(url,post_obj,trigger_from){
        if(_semaphoreXHR.use()){
            var forceObj=_semaphoreXHR.useForceLocation();
            if(forceObj!==false){
                url=forceObj.url;
                post_obj=forceObj.post_obj;
                trigger_from=undefined;
                url=prepareUrl(url);
            }

            _semaphoreXHR.wantSend(function(){
                $.mypost(url,post_obj,function(data){
                    afterReceiveXHR(data,url,trigger_from);
                });

            },url);

       }
       else{
       }
    }
    function afterReceiveXHR(data,url,obj_trigger){
        if (data.indexOf("<html") !=-1 && data.indexOf("antibotRELOAD") ==-1) {
            location.replace('/');
        }
        else{

            $("#xhrUseIt").html("").append(data);
//            if(tmp){
//                if (!_ON_DEV) {
//                    _gaq.push(['_trackPageview', "/" + tmp]);
//                }
//            }
            if(typeof obj_trigger!="undefined" && obj_trigger.length){
                if(obj_trigger.closest(".gameCoverContent").length){
                    //close popup, when change location from it
                    obj_trigger.closest(".gameCoverContent").find(".closeCover").eq(0).trigger("click");
                }
            }
        }
    }


    function prepareValues(values){
        if(values.length>0){
             values+="&fromXHR=1";
         }
         else{
             values="fromXHR=1";
         }
         return values;
    }
    function prepareUrl(action){
         var url="/";
         if(action==""){
             url="/"+getCurrentPage()+"/";
             url=url.replace('.','/');
         }
         else if(action!=='/'){
             url=action;
             if(url && url.indexOf("?")==-1 && url.substr(-1,1)!="/"){
                 url+="/";
             }
             if(url && url.substr(0,1)!=="/"){
                 url="/"+url;
             }

         }

         url = url.replace(".", "/");

         return url;
    }
    $.payByPremium=function(premium,on_confirm){
        var premium_have=_myProfile.takeData("premium_all");
        if(premium>premium_have){
            showCoverStatic("premiumnone");
        }
        else if(premium>=intval(_userArr.premium_conf)){
            showCoverStatic("premiumconf",{premiumConf:premium,on_confirm:on_confirm});
        }
        else{
            on_confirm();
        }
    };
    $.addToPlacePreloader=function(on_succ_fun,addToLoaderArr,opt){
      var moduleContent=$("#content_main");
      if(typeof opt=="undefined"){
          opt={};
      }
      opt.urlArr=addToLoaderArr;
      moduleContent.one("myPreloaderEnd",function(){
          on_succ_fun();
      }).myPreloader(opt,_externalTimeHandler);
    };
    $.triggerPlacePreloader=function(count){
        var moduleContent=$("#content_main");
        moduleContent.myPreloader("trigger",count);
    };
    $.addToEventPreloader=function(on_succ_fun,addToLoaderArr,opt){
        var moduleContent=$("#myGameCover");
        if(typeof opt=="undefined"){
            opt={};
        }
        opt.urlArr=addToLoaderArr;
        moduleContent.one("myPreloaderEnd",function(){
            on_succ_fun();
        }).myPreloader(opt,_externalTimeHandler);
    };
    $.triggerEventPreloader=function(count){
        var moduleContent=$("#myGameCover");
        moduleContent.myPreloader("trigger",count);
    };
   $.goToLocation=function(action,post_obj,trigger_from){
       if(!post_obj){
           post_obj={};
       }
       post_obj.fromXHR=1;
       var url=prepareUrl(action);
       sendXHR(url,post_obj,trigger_from);

   };
   $.fn.maxRow = function() {
        var max = 0, el, th;
        return $(this).each(function() {
            max = 0;
            th = $(this);
            if(th.is(":not([mrset])")){
                el = th.children();
                el.each(function() {
                    max = Math.max($(this).height(), max);

                });
                el.height(max);
                th.attr("mrset", "1");
            }
        });

    };
   $.fn.handleXHR=function(){
        function checkUrlUnable(form){
            var url="";
            var premium_need=0;
            if(form.find("[data-need]").length){
                var obj=form.find("[data-need]");
            }
            if(typeof obj!="undefined" && obj.is("[data-need]")){
                var toCheck={
                    "p":{"val":_myProfile.takeData("premium_all"),"event_static":"premiumnone","action":"premiumshop","add_p":0,"order":1},
                    "c":{"val":_myProfile.takeData("cash"),"action":"premiumcash","add_p":1,"order":2},
                    "e":{"val":_myProfile.takeData("energy"),"action":"explainenergy","add_p":1,"order":3},
                    "skill":{"val":"","action":"skills","add_p":1,"order":3},
                };
                var i,bum,val,type,stat_best,stat,need=obj.attr("data-need");
                need = need.split(",");
                for(i in need){
                    bum=need[i];
                    val=intval(bum);

                    type=bum.substring(val.toString().length);
                    var regexp_skill = /s([0-9]*)/g;

                    if(typeof type!="undefined"){

                        var match_skill = regexp_skill.exec(type);
                        if(match_skill && match_skill.length){
                            var skill_id=match_skill[1];
                            type="skill";
                            toCheck[type].val=_SkillSet.objSet[skill_id].getLevel()
                        }
                        stat=toCheck[type];
                        if(type=="c"){
                           if(stat && bigInt(val).gt(stat["val"]) && (typeof stat_best=="undefined" || stat_best['order']<stat['order'])){
                                stat_best=stat;
                                stat_best.val_need=val;
                            }
                        }
                        else{
                            if(stat && val>stat["val"] && (typeof stat_best=="undefined" || stat_best['order']<stat['order'])){
                                stat_best=stat;
                                stat_best.val_need=val;
                            }
                            else{
                                //have it
                                if(type=="p"){
                                    premium_need=val;
                                }
                            }
                        }
                    }
                } // for(i in need){
                if(typeof stat_best!="undefined"){
                    if(typeof stat_best.event_static!="undefined"){
                        showCoverStatic(stat_best.event_static);
                        url="event";
                    }
                    else{
                        url="/"+stat_best['action']+"/";
                        if(stat_best['add_p']){
                            url=url+""+stat_best["val_need"]+"/";
                        }
                        sendXHR(url,{fromXHR:1},form);
                    }
                }
            }
            _userArr.premium_conf=1;
            if(!url && premium_need>=intval(_userArr.premium_conf)){
                showCoverStatic("premiumconf",{premiumConf:premium_need});
                url="premiumconf";
            }
            return url;
        }




       var main_url=document.URL;
       main_url=main_url.split("//");
       main_url=main_url[1];
       main_url=main_url.split("/");
       main_url=main_url[0];
       $.updateButtons();
       return $(this).each(function(){
          var th=$(this);

          th.find(".formXHR .myButton,.formXHR .button,.formXHR .myImage").off("click.fxhr").on("click.fxhr",function(){

              var form=$(this).closest(".formXHR"),action="";
             if(form.is("[action]")){
                 action=form.attr("action");
             }
            if($(this).is(":not(.noSound)")){
                _mySound.playSound("button_click");
            }
            var values;
            values=form.find("input, textarea, select").filter(function(index) {
                if ($(this).closest(".formXHR")[0] == form[0]) {
                    return true;
                }
                return false;
            }).serialize();




            var url,url_request=prepareUrl(action);
            values=prepareValues(values);
            url=checkUrlUnable(form,url_request,values);
            if(url=="premiumconf"){
                _externalEvents.off("premiumconf").one("premiumconf",function(){
                    sendXHR(url_request,values,form);
                });
            }
            if(!url){
                sendXHR(url_request,values,form);
            }

            return false;
          });

          th.find(".myLink").off("click.xhr").on("click.xhr",function(event){
                var th=$(this),other_action;
                if(th.is(":not(.noSound)") && th.is(":not(.item)")){
                    _mySound.playSound("button_click");
                }
                if(th.is("[action]") && th.attr("action")){
                    if(th.is(".closeCoverRemoveModules")){
                        clearFromArrModules();
                    }
                    $.goToLocation(th.attr("action"),{},th);
                }
          });
       });
   };
   $.fn.actionQueue= function(opt){
       var data_name="actionQueue";
       var methods = {
            getOne: function(){
                var th=$(this);
                if(!th.is("[data-"+data_name+"]")){
                    return false;
                }
                var str=th.attr("data-"+data_name);
                if(!str){
                    return false;
                }
                var arr=str.split(",");
                if(!arr.length){
                    th.removeAttr("data-"+data_name);
                    return false;
                }
                else if(arr.length==1){

                    th.removeAttr("data-"+data_name).removeClass("newmessQueue");
                    return str;
                }
                else{
                    var ret= arr.shift();
                    th.attr("data-"+data_name,arr.join(","));
                    return ret;
                }
            },
            removeByAction:function(action){
                var th=$(this),arr=[];
                if(th.is("[data-"+data_name+"]")){
                    var str=th.attr("data-"+data_name);
                    if(str){
                        arr=str.split(",");
                    }
                }
                myRemoveByValue(arr,action);
                if(arr.length==0){
                    th.removeAttr("data-"+data_name).removeClass("newmessQueue");
                }
                else{
                    th.attr("data-"+data_name,arr.join(","));
                }
            },
            addOne: function(action,is_unique){
                var th=$(this),arr=[];
                if(th.is("[data-"+data_name+"]")){
                    var str=th.attr("data-"+data_name);
                    if(str){
                        arr=str.split(",");
                    }
                }
                if(!is_unique || !in_array(action,arr)){
                    arr.push(action);
                }
                th.addClass("newmessQueue").attr("data-"+data_name,arr.join(","));
            }
        };
        var arg =Array.prototype.slice.call(arguments,1);
        return methods[opt].apply(this,arg);
   };


   /*
    * data-need="1,2,10e,5n" have="3,0"
    *
    * compare data-need arguments(after semicolons) with data-have arguments.
    * If type is defined, get value from user's profile.
    * After comparission scrypt will add/remove unable class on buttons
    * and on some other elements(with classes 'set_{$type_id} set_icon').
    *
    *
    * types:
    * e - energy
    * p - premium
    * c - cash
    * l - level
    * s[0-9] - skill => 2s5: need 2 lvl of skill_id 5

    */
   $.fn.updateButton = function() {
        var i, bum, need, have, j, type, need_val, end = 0,
        energy = _myProfile.takeData("energy"), cash = _myProfile.takeData("cash"),
        points = _myProfile.takeData("premium_all"),
        form, cur_end, type_obj,
        arr = {"e": energy, "p": points, "c": cash,  "t": $.getCurrentTime(), "b": $.getCurrentTime(),
        };

        return this.each(function(){
            end = 0;
            bum = $(this);
            form = bum.closest(".formXHR");
            need = bum.attr("data-need");
            if (need) {
                need = need.split(",");
                have = bum.attr('data-have');
                if (have) {
                    have = have.split(",");
                }
                for (j = 0; j < need.length; j++) {
                    var tmp=need[j];
                    need_val = intval(need[j]);

                    type=need[j].substring(need_val.toString().length);

                    if(type == "c"){
                        tmp=tmp.split("c");
                        need_val=bigInt(tmp[0]);
                    }

                    if (typeof arr[type] != "undefined") {
                        cur_end = 0;
                        if (type == "t") {
                            if (need_val < arr[type]) {
                                cur_end = 1;
                            }
                        }
                        else {

                            if(type=="c" && arr[type].lt(need_val)){
                                _timerCash.addToStorage(need_val);
                                cur_end = 1;
                            }
                            else if(need_val > arr[type]) {
                                cur_end = 1;
                            }
                        }

                        var isMyNeed = bum.is(".myNeed[data-need]");
                        if (form.length || isMyNeed) {
                            if (isMyNeed) {
                                type_obj = bum.find(".set_" + type);
                            }
                            else {
                                type_obj = form.find(".set_" + type);
                            }

                            if (typeof type_obj != "undefined") {

                                if (cur_end) {
                                    type_obj.addClass("unable");
                                }
                                else {
                                    type_obj.removeClass("unable");
                                }
                            }

                        }
                        end = Math.max(end, cur_end);

                    }
                    else{
                        var regexp_skill = /s([0-9]*)/g;

//                        var matches_array = type.match(regexp);
                        var match_skill = regexp_skill.exec(type);
                        if(match_skill && match_skill.length){
                            var skill_id=match_skill[1];
                            if(_SkillSet.objSet[skill_id].getLevel()<need_val){
                                end=1;
                            }
                        }
                        else if (intval(need[j]) > intval(have[j])) {
                            end = 1;
                        }

                    }

                }
            }
            if (!end) {
                bum.add(form.find(".myRadio,.myCheckbox")).removeClass("unable").trigger("myenable");
            }
            else {
                bum.add(form.find(".myRadio,.myCheckbox")).addClass("unable").trigger("myunable");
            }
            bum.trigger("change");
        });
    };

   $.updateButtons = function(){
       $(".myButton[data-need],.myImage[data-need],.myNeed[data-need],.button[data-need]").updateButton();
   };


   /*special
    *
    * g_id=-1, write message
    * g_id=-2, are you sure?
    */

    $.fn.addBook= function(html){
        var ret="";
        this.each(function(){
            var place=$(this);
            place.append(html);
            place.manageBook();
            var book=place.find(".book:last-child");
            afterLoadModule(place);
            ret=book;
        });
        return ret;
    };

    $.fn.bookUse= function(opt){
        var methods = {
            show: function(play_sound,vars) {
                var no_sound=1;
                if(play_sound){
                    no_sound=0;
                }
                var th=$(this),place=th.closest(".place");
                place.find('.book').not(th).hide();
                onShowBook(place,th,vars,no_sound);

            },
            hide:function(){
                var th=$(this),place=th.closest(".place");
                onHideBook(place,th);
            }
        };
        var arg =Array.prototype.slice.call(arguments,1);
        return this.each(function() {
            if(methods[opt]){
                return methods[opt].apply(this,arg);
            }
        });

    };


    function onShowBook(up, book,vars,no_sound) {
        if (book.children(".qsmoke").length == 0 && !book.is(".noOpacityBook")) {
            up.addClass("opacity");
        }
        book.show();
        book.find(".textfill").textfill();
        book.trigger("open",vars);
        if(!no_sound){
            _mySound.playSound("button_click");
        }

    }

    function onHideBook(up, book) {
        if (typeof book != "undefined") {
            if(book.is(":visible")){
                up.removeClass("opacity");
                book.hide();
                book.trigger("close");
                up.find(".moduleArrow").show();
            }
        }

    }
    $.fn.manageBook = function() {
        return this.each(function() {
            var obj = $(this).find(".item"), i, item, id, book, up = $(this), books_ready = [],target_mouse_down=false;
            up.off("mousedown.bookup").on("mousedown.bookup",function(event){
                target_mouse_down=$(event.target);
            }).
            off("click.bookup").on("click.bookup", function(event) {
                var target = $(event.target), th = $(this);
                if (!th.hasClass("place")) {
                    th = th.closest(".place");
                }
                if (th.find("#overBook:visible").length || target.is("#overBook")) {
                    return false;
                }

                var book = th.find(".book:visible").eq(0), g_id = book.attr("g_id"), bum;
                bum = th.find(".item[g_id='" + g_id + "']").eq(0);
                if(book.length && (target.is(".closeBookTrigger") || target.closest(".closeBookTrigger").length)){
                    onhide(up, book);
                }
                else{
                    if (book.length && (intval(target.attr("g_id")) == g_id || intval(target.closest(".book").attr("g_id")) == g_id)) {
                    }
                    else {
                        if (target && !target.hasClass("item") && target.closest(".item").length == 0 && !book.hasClass("noCloseBook")) {
                            if(!target_mouse_down || (target_mouse_down!=false && target[0]==target_mouse_down[0])){
                                if (bum.length) {
                                    bum.trigger("myaction");
                                }
                                else {
                                    onhide(up, book);
                                }
                            }

                        }
                    }
                }

            });
            for (i = 0; i < obj.length; i++) {
                item = obj.eq(i);
                id = item.attr("g_id");
                book = up.find(".book[g_id='" + id + "']");
                if (book.length == 0) {
                    continue;
                }
                item.off("myaction").on("myaction", function(event) {
                    var target = $(event.target), book, up = $(this).closest(".place");
                    id = $(this).attr("g_id");
                    obj.find(".smoke").removeClass("blocked");
                    up.find(".book:not([g_id='" + id + "'])").hide();
                    book = up.find(".book[g_id='" + id + "']");
                    if (book.is(":visible")) {
                        onhide(up, book);

                        $(this).find(".smoke").removeClass("blocked");
                        ;//.trigger("mouseover");
                        if (target.is("* .smoke")) {
                            $(this).trigger("mouseover");
                        }
                        //
                    }
                    else {
                        onShowBook(up, book);


                        $(this).find(".smoke").addClass("blocked");
                        $("#smoke").hide();

                    }
                }).off("click.item").on("click.item",function() {
                    $(this).trigger("myaction");
                    return false;
                });

            }
            obj = $(this).find(".book");
            for (i = 0; i < obj.length; i++) {
                book = obj.eq(i);
                book.hide();
                if (book.is("[show]")) {
                    item = up.find(".item[g_id=" + intval(book.attr("g_id")) + "]");
                    if (item.length) {
                        item.eq(0).trigger("click");
                    }
                    else {
                        onShowBook(up, book,false,1);
                    }
                    book.removeAttr("show");
                }
                if (book.children(".qsmoke").length == 0) {
                    if (!book.find(".closeBook").length && !book.is(".noCloseBook")) {
                        if (book.is("[hright]")) {
                            book.append("<div class='closeBook' style='top:" + intval(book.attr("htop")) + "px;right:" + intval(book.attr("hright")) + "px;'></div>");
                        }
                        else {
                            book.append("<div class='closeBook'></div>");
                        }
                    }


                    book.find(".closeBook, .closeBookButton").off("click.book").on("click.book",function() {
                        var th = $(this), book = th.closest(".book"), g_id = book.attr("g_id"), bum, up = book.closest(".place");
                        bum = up.find(".item[g_id='" + g_id + "']").eq(0);
                        if (bum.length) {
                            bum.trigger("myaction");
                        }
                        else {
                            onhide(up, book);
                        }
                        return false;
                    });
                }


                if (book.is(".ready")) {
                    book.hide();
                    item.trigger("myaction");
                }
                books_ready.push(id);

            }
            function onhide(up, book) {
                onHideBook(up,book);
            }
        });



    };



   $("#myGameCoverOpacity").click(function(event){
       /**/
       var th=$(this),win=th.find(".gameCoverContent.popup"),arr=["premiumone"];
        if ($(event.target)[0]===$(this)[0] && win.length && !in_array(win.attr("data-page"),arr)) {
            gameCoverNext();
        }
   });







    $('#cover').click(function() {
         $('#cover').hide();
         $('#content_big_message').remove();
         $('#content_confirm').remove();
    });





    function ajaxProfile(){
         //update user profile
        var lastHardWork=$.getCurrentTime()+120;
        var hardTimer;
        var updatePanelTimer=setTimeout(function(){}, 10);
        var keepAliveInterval= setInterval(function(){},10000);

        setTimeout(function(){
            $(window).focus(function(){
                if(lastHardWork<$.getCurrentTime()){
                    clearTimeout(hardTimer);
                    clearInterval(keepAliveInterval);
                    hardTimer=setTimeout(function(){
                        if(lastHardWork<$.getCurrentTime()){
                            Panel2UpdateAjax();
                        }
                    }, 200);
                }
            });
        },10000);
        /*$(window).blur(function() {
            clearTimeout(hardTimer);
            hardTimer=setTimeout(function(){
                clearTimeout(updatePanelTimer);
                clearInterval(keepAliveInterval);
                keepAliveInterval=setInterval(function(){
                    $.mypost("/ajax",{page:"keepalive"});
                },300000);
            }, 200);
        });*/


        function reWriteUpdatePanel(){
            lastHardWork=$.getCurrentTime()+120;
            clearTimeout(updatePanelTimer);
            clearInterval(keepAliveInterval);
            updatePanelTimer=setTimeout(function(){
                Panel2UpdateAjax();
            }, 60000);
        }
        $.reWriteUpdatePanel=function(){
            reWriteUpdatePanel();
        };
        function Panel2UpdateAjax(){
            reWriteUpdatePanel();
            $.mypost('/ajax',{page:'profile'}, function(res) {
                var data=handleAjaxRes(res);
                if(typeof data!="undefined" && data){
                    $("body,#triggerModuleJS").trigger("panelUpdate");
                }
            },"json");
        }
        $.updatePanel=function(){
            Panel2UpdateAjax();
        };

    }
    ajaxProfile();



});







/* czas */
/*      */

$(function(){
//   var startTimeServer=Math.floor(intval($("#current_time").data('time'))-(new Date()).getTime()/1000);

    $.fn.cssrotate = function(d) {
        return this.css({
            '-moz-transform': 'rotate(' + d + 'deg)',
            '-webkit-transform': 'rotate(' + d + 'deg)',
            '-o-transform': 'rotate(' + d + 'deg)',
            '-ms-transform': 'rotate(' + d + 'deg)'
        });
    };

    $.fn.premiumLess=function(options){
        if(!options){
            options={};
        }
        function setValue(obj,price_cur,price_old){
            function updateDataNeed(){
                var data_need=obj.attr("data-need"),need_arr=[],change=0;
                if(data_need){
                    need_arr=data_need.split(",");
                    var i,val;
                    for(i in need_arr){
                        val=need_arr[i];
                        if(val.slice(-1)=="p"){
                            need_arr[i]=price_cur+"p";
                            change=1;
                        }
                    }
                }
                if(change==0){
                    need_arr.push(price_cur+"p");
                }
                obj.attr("data-need",need_arr.join(",")).updateButton();
            }
            updateDataNeed();
            var html="";

            if(price_cur==price_old){
                obj.removeClass("bonusLess");
                html="<div>"+price_cur+"</div>";
            }
            else{
                obj.addClass("bonusLess");
                html="<div class='price old'>"+price_old+"</div><div class='price'>"+price_cur+"</div>";
            }
            obj.find(".in").html(html);
        }
        var methods = {
            update: function(price_cur,price_old){
                var th=$(this), set=th.data("myPremiumLess");
                set.price_cur=price_cur;
                if(price_old>0){
                    set.price_old=price_old;
                }
                th.data("myPremiumLess",set);
                setValue(th,set.price_cur,set.price_old);
            }
        };
        var last_this=this;
        var arg =Array.prototype.slice.call(arguments,1);
        return this.each(function() {
            if(methods[options]){
                return methods[options].apply(last_this,arg);
            }
            else if (typeof options === 'object' || !options){
                var $this = $(this),
                data = $this.data("myPremiumLess");
                var settings;

                if(!data){
                    var settings = $.extend( {
                        price_cur : 0,
                        price_old:    0
                    }, options);
                    if(settings.price_cur==0){
                        settings.price_cur=intval($this.attr("data-price_cur"));
                    }
                    if(settings.price_old==0){
                        settings.price_old=intval($this.attr("data-price_old"));
                    }
                }
                else{
                    settings=$.extend(data,options);
                }
                setValue($this,settings.price_cur,settings.price_old);
                data = $this.data("myPremiumLess", settings);



                return;
            }
            else{
                if(typeof methods[options]=="function"){
                    return methods[options].apply(last_this,arg);
                }
            }
        });
    };

    $.fn.realDate=function(format_id){
         var serv_time = $.getStartTimeServer();

         return this.each(function(){
             var th=$(this);

             th.html(dateFormat(intval(th.attr("data-time")) + serv_time,format_id));
         });
     };

     $.fn.beltTimer=function(howlong_have,howlong_end,time_end,options,timeHandler){
        if(!timeHandler){
            timeHandler=_moduleTimeHandler;
        }
        if(!options){
            options={};
        }
        var settings = $.extend( {
            interval : 150,
            on_end:function(){},
            on_step:function(percent){},
            on_end_timer:function(){},
            css_type:"width",

        }, options);
        return this.each(function(){
            var th=$(this);
            var data = th.data("beltTimer");
            if(!data){
                data={index:timeHandler.getNewIndex()}
                th.data("beltTimer",data);
            }
            else{
                timeHandler.clear(data.index);
            }
            if(howlong_have!=="clear"){
                var miliStart=new Date().getTime(),miliEnd=miliStart+(time_end-getCurrentTime())*1000,percent_start=howlong_have/howlong_end*100;

                if(miliStart<miliEnd){
                    timeHandler.set(data.index,function(){
                        var miliCur=new Date().getTime();
                        var percent;
                        if(miliEnd>miliCur){
                            percent=percent_start+(100-percent_start)*((miliCur-miliStart)/(miliEnd-miliStart));
                        }
                        else{
                            percent=101;
                        }
                        var end=0;
                        if(percent>100){
                            percent=100;
                            if(time_end<=getCurrentTime()){
                                timeHandler.clear(data.index);
                                end=1;
                            }
                        }
                        th.css(settings.css_type,percent+"%");
                        settings.on_step(percent);
                        if(end){
                            settings.on_end();
                            settings.on_end_timer();
                        }
                    },settings.interval);
                }
                else{
                    var percent=100;
                    th.css(settings.css_type,percent+"%");
                    settings.on_step(percent);
                    settings.on_end();
                }
            }
        });
    };

    $.showCustomEvent=function(type){
        var send_obj={page:"eventshow",event_type:type};
        $.mypost('/ajax',send_obj, function(res){
            handleAjaxRes(res);
        },"json");

    };

    $.tutorialTextFit=function(){
        var tutBox=$(".tutorialBox:visible>.content").eq(0);
        if(tutBox.length){

            tutBox.find(">.cap .textTut").textfill({maxFontPixels:33});
            tutBox.find(">.desc .textTut").textfill({maxFontPixels:25});
            if(!_googleFontAfterInit.wasLoaded()){
                _googleFontAfterInit.addFun(function(){
                    tutBox=$(".tutorialBox:visible>.content").eq(0);
                    tutBox.find(">.cap .textTut").textfill({maxFontPixels:33});
                    tutBox.find(">.desc .textTut").textfill({maxFontPixels:25});
                });
            }



        }

    };


    /*
     * whole have same container
     * container .myScaleContainer
     * item .myScaleItem
     *
     */
    $.fn.myScaleToObj=function(options,scale_from_obj){
        options=$.extend({width:100,height:100},options);
        var container,container_data;
        container=scale_from_obj;
        container_data=container.data("myScaleToObj");
        if(!container_data || typeof container_data.o_width=="undefined"){
            container_data={};
            container.css({width:"",height:""});
            container_data["o_width"]=container.width();
            container_data["o_height"]=container.height();
            container_data["scale"]=0;
            container.data("myScaleToObj",container_data);
        }
        var scale=Math.min(options.width/container_data["o_width"],options.height/container_data["o_height"]);
        scale=Math.min(1,scale);
        if(scale!=container_data["scale"]){
             container_data["scale"]=scale;
             container_data["n_width"]=Math.round(container_data["o_width"]*scale);
             container_data["n_height"]=Math.round(container_data["o_height"]*scale);
        }
        this.each(function(){
            var th=$(this),data=th.data("myScaleToObjItem");
            if(!data || typeof data.o_width=="undefined"){
                data={};
                th.css({width:"",height:"",left:"",top:""});
                data["o_width"]=th.width();
                data["o_height"]=th.height();
                data["o_left"]=intval(th.css("left"));
                data["o_top"]=intval(th.css("top"));
                data['scale']=0;
                th.data("myScaleToObjItem",data);
            }
            if(data.scale!=scale){
                data.scale=scale;
                th.css({
                    width:Math.round(data["o_width"]*scale)+"px",
                    height:Math.round(data["o_height"]*scale)+"px",
                    top:Math.round(data["o_top"]*scale)+"px",
                    left:Math.round(data["o_left"]*scale)+"px",
                });
            }
        });
        return copyObj(container_data);
    };
});




function numberFormat(number,end_big,is_big,end_small,big_from_val){
    //end_small->ile po przecinku, dla float
    //end_big -> ile po przecinku przy postfixach
    //is_big -> czy pokazywac postfix
    //big_from_val -> od jakiej liczby wyswietlac posfix
    var sign="";
    if(number<0){
        sign="-";
    }
    number=Math.abs(number);
    if(typeof is_big=="undefined"){
        is_big=1;
    }
    if(typeof end_big=="undefined" && is_big){
        end_big=1;
    }
    else if(typeof end_big=="undefined"){
        end_big=0;
    }
    if(typeof is_big=="undefined"){
        is_big=1;
    }
    if(typeof big_from_val!="undefined"){
        if(number>=big_from_val){
            is_big=1;
        }
        else{
            is_big=0;
        }
    }
    function prepareEnd(number,end){
        number=Math.round(number*Math.pow(10,end))/Math.pow(10,end);
        var zero=0,i;
        var add=number-intval(number);
        add=Math.round(add*Math.pow(10,end));
        if(add==Math.pow(10,end)){
            add--;
        }
        for(i=end-1;i>0 && add<Math.pow(10,i);i--){
            zero++;
        }
        for(i=0;i<zero;i++){
            add="0"+add;
        }
        return add;
    }






    var temp_number=intval(number);

    var text="";
    if(!is_big || number<1000){
        while(temp_number>=1000 || temp_number<=-1000){
            temp=temp_number%1000;
            if(temp<0){
                temp=temp*(-1);
            }
            if(temp==0){
                temp="000";
            }
            else if(temp<10){
                temp="00"+temp;
            }
            else if(temp<100){
                temp="0"+temp;
            }
            text=temp+" "+text;
            temp_number=intval(temp_number/1000);
        }
        text=(temp_number)+" "+text;
        text=text.trim();
        if(end_small){
            text+="."+prepareEnd(number,end_small);
        }
        return sign+text;
    }

    //bbb

    number=intval(number);

    var temp,temp_number;
    var pre={
        "Sp":[Math.pow(10,24),"Septillion"],
        "sx":[Math.pow(10,21),"Sextillion"],
        "Qn":[Math.pow(10,18),"Quintilion"],
        "qd":[Math.pow(10,15),"Quadrillion"],
        "T":[Math.pow(10,12),"Trillion"],
        "B":[Math.pow(10, 9),"Billion"],
        "M":[Math.pow(10,6),"Million"],
        "k":[Math.pow(10,3),"Thousand"]
    };
    var floor=Math.pow(10,intval(end_big));
    for(temp in pre){
        temp_number=pre[temp][0];
        if(temp_number<=number){
            text=(Math.round(number/temp_number*floor)/floor)+temp;
            break;
        }
    }
    return sign+text;

}

function cashFormat(number,end_big,big_from_number){
    if(typeof big_from_number=="undefined"){
        big_from_number=1000000;
    }
    if(typeof end_big=="undefined"){
        end_big=1;
    }
    number=intval(number);
    if(number>=0){
        number="$"+numberFormat(number,end_big,1,0,big_from_number);
        return number;
    }
    number="- $"+numberFormat(number*(-1),end_big,1,0,big_from_number);
    return number;

}



$(function(){
  $.handleBottomMenu=function(){
      var toHide=$("#mainButtonsBar,#mainBeltRightDownCorner,#ic_premium");
      toHide.css("bottom","-100px")
      _moduleEvents.on("moduleClear",function(){
          toHide.removeAttr("style");
      });
  };
  $.handleSelect=function(){
      var body=$("body");
      body.addClass("noselect");
      _moduleEvents.one("moduleClear",function(){
         body.removeClass("noselect");
      });
  };
    function prepareWindowEvents(){
        var eventNames=["click","mousedown","mouseup"],i;
        for(i in eventNames){
            val=function(){
                var eventName=eventNames[i];
                $(window).on(eventName,function(e){
                    _eventHandler.trigger(eventName+"All",e);
                });
            }
            val();
        }
        //_eventHandler
    }

    $.closeModulePage=function(){
        gameCoverNext();
    };
    function initViewSize(){
        var resizeIndex=_externalTimeHandler.getNewIndex();
        function updateView(){
            function countScale(conf,org_size){
                var scale;
//                if(org_size[0]/conf[0]<org_size[1]/conf[1]){
//                scale=org_size[0]/conf[0];
//    //                transform_orig="left";
//                }
//                else{
//                    scale=org_size[1]/conf[1];
//                }
                scale=Math.min(org_size[0]/conf[0],org_size[1]/conf[1])
                scale=Math.min(scale,1);
                return scale;
            }
            var addSpace=1,conf_all=[[1318,914],[1318,934]];
            var whole=$("#gameWhole");
            addSpace=0;
            var conf=conf_all[addSpace];
            var contAllObj=$("#windowAllCont");
            var org_size=[contAllObj.width(),contAllObj.height()];
            if(org_size[1]>=1450){
                contAllObj.addClass("bigResolution");
            }
            else{
                contAllObj.removeClass("bigResolution");
            }
            var scale=1;
            _scaleObj.body=org_size;

            scale=countScale(conf_all[0],org_size);
//            if(scale==countScale(conf_all[1],org_size)){
//                addSpace=1;
//            }


            if(addSpace){
                whole.addClass("space");
               _scaleObj.space=$("#gameWindowSpace").height();
            }
            else{
                whole.removeClass("space");
                _scaleObj.space=0;
            }
            _scaleObj.scale=scale;
            whole.css({
                '-webkit-transform' : 'scale(' + scale + ')',
                '-moz-transform'    : 'scale(' + scale + ')',
                '-ms-transform'     : 'scale(' + scale + ')',
                '-o-transform'      : 'scale(' + scale + ')',
                'transform'         : 'scale(' + scale + ')',
//                "-webkit-transform-origin": transform_orig+" top",
//                "-moz-transform-origin": transform_orig+" top",
//                "-ms-transform-origin": transform_orig+" top",
//                "-o-transform-origin": transform_orig+" top",
//                "transform-origin": transform_orig+" top"
            });
        }
        $(window).on("orientationchange resize", function() {
            _externalTimeHandler.clear(resizeIndex);
            _externalTimeHandler.setOne(resizeIndex,function(){
                updateView();
            },100);

        });
        updateView();
    }
    initViewSize();
    $.fn.clearIntInput= function(){
        var obj = $(this);
        var val = obj.val(),val_old=val;
        val = textToInt(val);
        if(val_old.trim()!=numberFormat(val)){
            obj.val(numberFormat(val));
        }

    };
    $(".textfill").textfill();
    _googleFontAfterInit.addFun(function(){
        $(".textfill").textfill();
    });
    $("#mainEmailConf[data-type='email']").click(function(){
        $.showCustomEvent("emailconf");
    });


    $('#currentFlag').click(function () {
        $( ".fwin" ).show();
    });
    $("#messageBox").hide().add("#messageBox .closeBox").click(function(e){
        if($(e.target)[0]==$("#messageBox")[0] || $(e.target)[0]==$("#messageBox .closeBox")[0]){
            $("#messageBox").hide();
        }

    });
    prepareWindowEvents();
    $.fn.promoBlinkPlay = function () {
      var out=false,wait=6, main = $(this);
      _moduleTimeHandler.set(_moduleTimeHandler.getNewIndex(),function () {
        var setOpacity = 0.3;
        if(wait>0){
          wait--;
          return;
        }
        if(out){
          setOpacity = 1;
          out = false;
          wait = 6;
        } else {
          out=true
        }
        main.find(".fakelayPromo .btnBig").animate({opacity:setOpacity},700);
      },400);
    }
    $.handleBottomMenu=function(){
      var toHide=$("#mainButtonsBar,#mainBeltRightDownCorner,#ic_premium");
      toHide.css("bottom","-100px")
      _moduleEvents.on("moduleClear",function(){
        toHide.removeAttr("style");
      });
    };
    $.handleSelect=function(){
      var body=$("body");
      body.addClass("noselect");
      _moduleEvents.one("moduleClear",function(){
        body.removeClass("noselect");
      });
    };
});
